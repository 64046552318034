<template>
  <div class='ecp-table'>
    <!--   项目组绩效目标： 筛选条件-->
    <el-row>
      <el-col :span='24'>
        <!--        筛选条件-->
        <ConditionView @searchEvent='searchEvent' style='display: inline-block' />
        <!--        操作项-->
        <el-form inline style='display: inline-block'>
          <el-form-item>
            <el-button type='primary' @click='getList' icon='el-icon-search' v-if="setAuthority('ecp_team_list')">查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='handleAdd' icon='el-icon-plus' v-if="setAuthority('ecp_team_create')">
              添加
            </el-button>
          </el-form-item>
          <!--          <el-form-item>-->
          <!--            <el-button type='danger' :disabled='multiple' @click='handleDelete' icon='el-icon-delete' v-if='userPermissions.indexOf("ecp_team_delete")>-1'>删除</el-button>-->
          <!--          </el-form-item>-->
          <el-form-item>
            <el-button type='warning' @click='dialogVisibleInit = true' icon='el-icon-refresh'
                       v-if="setAuthority('ecp_team_init')">初始化
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button v-if="setAuthority('ecp_team_export')" v-loading='downLoading' @click='exportDownload'>
              <i :class="downLoading ? 'el-icon-loading' : 'el-icon-download'">导出</i>
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-link type='primary' @click='gotoKolMapper' icon='el-icon-setting' v-if="setAuthority('ecp_kol_mapper')">
              管理项目组红人关联
            </el-link>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!--    绩效数据-->
    <el-row>
      <el-col :span='24'>
        <el-table
            :data='quarterDataList'
            :show-summary='true'
            :summary-method='getSummaries'
            @expand-change='expandFun'
            :expand-row-keys='expands'
            @row-dblclick='expandRow'
            v-loading='tableLoading'
            ref='tableList'
            row-key='group_id'
            :table-sort="{ prop: 'dept_name', order: 'ascending' }"
            @selection-change='handleSelectionChange'
            border
        >
          <el-table-column type='expand' fixed='left'>
            <template slot-scope='scope'>
              <TeamMember
                  :member-list='scope.row.child'
                  :quarterlyMonths='quarterlyMonths'
                  :rowData='scope.row'
                  style='width: 99%'
                  @getList='getList'
                  @editRow='handleEdit(scope.row, $event, scope.$index)'
                  :lastQuarterlyMonths='lastQuarterlyMonths'
              />
            </template>
          </el-table-column>
          <!--          <el-table-column type='selection' width='55' align='center' />-->
          <!--          <el-table-column label='ID' prop='id' width='100' align='center'></el-table-column>-->
          <el-table-column :key="'m_leader'" label='管理负责人' fixed='left' prop='m_leader' width='120'
                           align='center'></el-table-column>
          <el-table-column :key="'dept_name'" label='项目组' prop='dept_name' width='130' show-overflow-tooltip
                           fixed='left' align='center'>
            <template slot-scope='{ row }'>
              <span v-if='row.group_name'>{{ row.group_name || '' }}</span>
            </template>
          </el-table-column>
          <el-table-column width='120' show-overflow-tooltip label='项目组长/副组长' prop='leader_name'
                           fixed='left' align='center'></el-table-column>
          <el-table-column label='上季度总利润' prop='last_total_profit' width='130' show-overflow-tooltip
                           fixed='left' :key="'last_total_profit'" align='right'>
            <template slot-scope='{ row }'>
              <span>{{ moneyFormat(row.last_total_profit) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop='last_gmv_agv' width='120' label='上季平均' header-align='center' :key="'last_gmv_agv'"
                           fixed='left' align='right'>
            <template slot-scope='{ row, column }'>
              <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
            </template>
          </el-table-column>
          <!--          ecp_bonus-->
          <!--          季度奖金包金额-->
          <el-table-column label='一季度奖金包金额' prop='bonus_quarter' width='150' show-overflow-tooltip
                           fixed='left' :key="'bonus_quarter'" header-align='center' align='right'>
            <editable-cell
              v-if="row.is_group && setAuthority('ecp_team_edit_bonusAll')"
              :show-input='row[`edit_bonus_${column.property}`]'
              slot-scope='{ row, column }'
              v-model='row[column.property]'
              @change='editBons(row, column.property)'
              :is-input-number='true'
            >
              <span slot='content'>
                <span class='money'>{{ $utils.numberFormat(row[column.property], 2) }}</span>
                <i class='el-icon-edit ecp-edit-icon'></i>
              </span>
            </editable-cell>
            <span v-else :class="[row.is_group ? 'bonus-quarter' : '']">
              {{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}
            </span>
          </el-table-column>
          <!--          本季度GMV-->
          <template v-for='month in quarterlyMonths' align='center'>
            <el-table-column :key='`net_profit_${month}`' :prop='`net_profit_${month}`' :label='`${month}月净利润`'
                             width='130' show-overflow-tooltip header-align='center' align='right'>
              <template slot-scope='{ row, column }'>
                <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column :key='`quotient_${month}`' :prop='`quotient_${month}`' label='浮动系数' width='130'
                             show-overflow-tooltip header-align='center' align='right'>
              <template slot-scope='{ row, column }'>
                <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column :key='`guard_gmv_${month}`' :prop='`guard_gmv_${month}`' :label='`${month}月保底`'
                             width='130' show-overflow-tooltip header-align='center' align='right'>
              <template slot-scope='{ row, column }'>
                <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column :key='`real_gmv_${month}`' :prop='`real_gmv_${month}`' :label='`${month}月实际`'
                             width='130' show-overflow-tooltip header-align='center' align='right'>
              <template slot-scope='{ row, column }'>
                <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column :key='`bonus_${month}`' :prop='`bonus_${month}`' :label='`${month}月奖金`' width='130'
                             show-overflow-tooltip header-align='center' align='right'>
              <template slot-scope='{ row, column }'>
                <span style='cursor: pointer;border:1px dashed;'
                      :style="row[column.property]>0?{'borderColor':'red'}:{}" class='money'
                      @click='editVal(row, month)'>{{
                    $utils.numberFormat(row[column.property], 2)
                  }}<i class='el-icon-edit' style='padding-left: 2px'></i>
                </span>
              </template>
            </el-table-column>
            <el-table-column :key='`completion_${month}`' :prop='`completion_${month}`' :label='`${month}月完成率`'
                             width='130' show-overflow-tooltip header-align='center' align='right'>
                            <template slot-scope='{ row, column }'>
                              <span>{{ row[column.property] ?  $utils.numberFormat(row[column.property], 2) + '%' : ''}}</span>
                            </template>
            </el-table-column>
          </template>
        </el-table>
        <br />
        <Tips :isShowAll='true' style='float: left' :title="'季度奖金包备注'" :data='tipList' />
        <Tips :isShowAll='true' style='float: left' :title="'计算公式说明'" :data='tipCountList' />
      </el-col>
    </el-row>

    <create-edit-team-data
      v-if='dialogVisible'
      :visible.sync='dialogVisible'
      :id='currentId'
      :search-condition='searchCondition'
      :quarterBonusList='quarterBonusList'
      :quarterRow='quarterDataList'
      @close='getList'
    />
    <!--    初始化绩效-->
    <TeamDataInit :visible.sync='dialogVisibleInit' :search-condition='searchCondition' @close='getList' />
    <!--    奖金-->
    <el-dialog :title='`${rowMonth}月${calcBonus.dept_name}-${calcBonus.group_name}组信息`' :visible.sync='editDialog'
               width='40%' :before-close='closeBefore'>
      <el-row :gutter='22'>
        <el-col :span='12' class="dialogBox">
          <el-card class="box-card">
            <div class="boundInfo">
          <span @click='userValue(Number(calcBonus.bonus_quarter))' style="cursor: pointer"
                title="使用该数据">当季度组奖金包额度：￥{{ $utils.numberFormat(calcBonus.bonus_quarter, 2) }}
            <i class='el-icon-document-copy'></i>
          </span>
              <br/>
              <span>{{ this.rowMonth }}月实际：￥{{ $utils.numberFormat(calcBonus[`real_gmv_${rowMonth}`], 2) }}</span
              ><br/>
              <span>{{ this.rowMonth }}月保底：￥{{ $utils.numberFormat(calcBonus[`guard_gmv_${rowMonth}`], 2) }}</span
              ><br/>
              <br/>
              <span style='cursor: pointer' title='使用该数据'
                    @click='userValue((Number(calcBonus[`real_gmv_${rowMonth}`]) - Number(calcBonus[`guard_gmv_${rowMonth}`])) * 0.1)'>{{
                  this.rowMonth
                }}月计算奖金：￥{{
                  $utils.numberFormat((Number(calcBonus[`real_gmv_${rowMonth}`]) - Number(calcBonus[`guard_gmv_${rowMonth}`])) * 0.1, 2)
                }}   <i class='el-icon-document-copy'></i>
              </span> <br/>
              <span @click='userValue(Number(calcBonus[`bonus_${rowMonth}`]))'
                    style='cursor: pointer' title='使用该数据'>  {{ this.rowMonth }}月当前奖金：￥{{
                  $utils.numberFormat(Number(calcBonus[`bonus_${rowMonth}`]), 2)
                }}   <i class='el-icon-document-copy'></i>
          </span>
            </div>
          </el-card>
          <div class="infoBox">
            <i style='font-size: 16px; color: rgb(255, 49, 118);'
               class='el-icon-info'></i>
            <span style="font-size: 14px">奖金计算公式：</span><br/>
            1.当月组奖金: (当月实际-当月保底)*10%<br/>
            1.1 当月奖金小于等于0时，取0<br/>
            1.2 当月奖金大于0，小于季度奖金包时，取计算值<br/>
            1.3 当月奖金大于等于季度奖金包时，取季度奖金包值<br/>
          </div>
        </el-col>
        <el-col :span='12'>
          <span>
            请输入修改的奖金：<br /><br />
            <el-input oninput="value=value.replace(/[^0-9.]/g,'')" style='width: 200px' clearable
                      v-model='editBonsValue'></el-input> </span
          ></el-col>
      </el-row>
      <span slot='footer' class='dialog-footer'>
        <el-button @click='editDialog = false'>取 消</el-button>
        <el-button type='primary' @click='saveBonus'>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ConditionView from '@/pages/commerce/performance/ConditionView'
import CreateEditTeamData from '@/pages/commerce/performance/CreateEditTeamData'
import TeamDataInit from '@/pages/commerce/performance/TeamDataInit'
import EditableCell from '@/components/EditableCell'
import { mapGetters } from 'vuex'
import TeamMember from '@/pages/commerce/performance/TeamMember'
import FileSaver from 'file-saver'

export default {
  name: 'TeamView',
  components: { EditableCell, TeamDataInit, CreateEditTeamData, ConditionView, TeamMember },
  computed: {
    ...mapGetters(['userPermissions']),
    //上季度月份
    lastQuarterlyMonths() {
      let lastMonths = []
      if (this.searchCondition.year && this.searchCondition.quarter) {
        // 计算上季度月份数据
        let lastQuarter = this.searchCondition.quarter - 1
        if (lastQuarter === 0) {
          lastQuarter = 4
        }
        lastMonths = [lastQuarter * 3 - 2, lastQuarter * 3 - 1, lastQuarter * 3]
      }

      return lastMonths
    },

    // 本季度月份
    quarterlyMonths() {
      let months = []
      if (this.searchCondition.quarter > 0) months = [this.searchCondition.quarter * 3 - 2, this.searchCondition.quarter * 3 - 1, this.searchCondition.quarter * 3]

      return months
    },
    quarterDataList() {
      // let data = [...this.dataList]
      let data = []
      let groupMap = {}
      let childMap = []
      // let lastGroupName = ''
      // let lastDeptName = ''
      this.dataList.forEach((row, index) => {
        let currentGroupName = row.group_name

        this.lastQuarterlyMonths.forEach((lastMonth) => {
          if (lastMonth % 3 === 1) {
            row[`real_gmv_${lastMonth}`] = row.last_gmv_first
          } else if (lastMonth % 3 === 2) {
            row[`real_gmv_${lastMonth}`] = row.last_gmv_second
          } else if (lastMonth % 3 === 0) {
            row[`real_gmv_${lastMonth}`] = row.last_gmv_third
          }
        })
        // 本季度月份数据
        let items = row.items || []
        items.forEach((_) => {
          row[`net_profit_${_.month}`] = _.net_profit
          row[`quotient_${_.month}`] = _.quotient
          row[`guard_gmv_${_.month}`] = _.guard_gmv
          row[`real_gmv_${_.month}`] = _.real_gmv
          row[`bonus_${_.month}`] = _.bonus
          row[`completion_${_.month}`] = _.completion

        })

        row['last_gmv_agv'] = (row.last_gmv_first / 1 + row.last_gmv_second / 1 + row.last_gmv_third / 1) / 3
        // 分组汇总
        if (groupMap[`${row['group_name']}`]) {
          this.lastQuarterlyMonths.forEach((lastMonth) => {
            if (lastMonth % 3 === 1) {
              groupMap[`${row['group_name']}`][`real_gmv_${lastMonth}`] += row.last_gmv_first / 1
            } else if (lastMonth % 3 === 2) {
              groupMap[`${row['group_name']}`][`real_gmv_${lastMonth}`] += row.last_gmv_second / 1
            } else if (lastMonth % 3 === 0) {
              groupMap[`${row['group_name']}`][`real_gmv_${lastMonth}`] += row.last_gmv_third / 1
            }
          })
          // 奖金
          items.forEach((_) => {
            groupMap[`${row['group_name']}`][`guard_gmv_${_.month}`] += _.guard_gmv ? _.guard_gmv / 1 : 0
            groupMap[`${row['group_name']}`][`net_profit_${_.month}`] += _.net_profit ? _.net_profit / 1 : 0
          })
        } else {
          // 分组数据初始化
          groupMap[`${row['group_name']}`] = {
            is_group: true,
            dept_id: row['dept_id'],
            dept_name: row['dept_name'],
            group_id: row['group_id'],
            group_name: row['group_name'],
            year: row['year'],
            quarter: row['quarter']
          }

          this.lastQuarterlyMonths.forEach((lastMonth) => {
            if (lastMonth % 3 === 1) {
              groupMap[`${row['group_name']}`][`real_gmv_${lastMonth}`] = row.last_gmv_first / 1
            } else if (lastMonth % 3 === 2) {
              groupMap[`${row['group_name']}`][`real_gmv_${lastMonth}`] = row.last_gmv_second / 1
            } else if (lastMonth % 3 === 0) {
              groupMap[`${row['group_name']}`][`real_gmv_${lastMonth}`] = row.last_gmv_third / 1
            }
          })
        }
        childMap.push(row)
        //插入项目组
        let nextRow = this.dataList[index + 1]
        let nextGroupName = nextRow ? nextRow.group_name : null
        // let nextDeptName = nextRow ? nextRow.dept_name : null
        //push 合计的数据
        if (index >= 0 && currentGroupName !== nextGroupName) {
          //新分组开始 不等上次分组， 则插入一行：上个分组的汇总数据
          // 汇总数据
          if (groupMap[currentGroupName]) {
            //计算季度奖金包：
            let groupRow = { ...groupMap[currentGroupName] }
            //如果组相同，推进child字段中
            let mapGroup = childMap
              .map((i) => {
                return i.group_id === groupRow.group_id ? i : false
              })
              .filter(Boolean)
            let last_total_profit = 0
            let last_gmv_agv = 0

            let net_profit = []
            // let bonus = 0
            //一个组内的child循环
            mapGroup.forEach((i) => {
              last_total_profit = Number(last_total_profit) + Number(i.last_total_profit)
              last_gmv_agv = Number(last_gmv_agv) + Number(i.last_gmv_agv)
              this.quarterlyMonths.forEach((month) => {
                if (i[`net_profit_${month}`]) {
                  groupRow[`net_profit_${month}`] = (Number(groupRow[`net_profit_${month}`]) || 0) + Number(i[`net_profit_${month}`] || 0)
                }
                groupRow[`guard_gmv_${month}`] = (Number(groupRow[`guard_gmv_${month}`]) || 0) + Number(i[`guard_gmv_${month}`] || 0)
                groupRow[`real_gmv_${month}`] = (Number(groupRow[`real_gmv_${month}`]) || 0) + Number(i[`real_gmv_${month}`] || 0)
                if (Number(i[`guard_gmv_${month}`])) {
                  groupRow[`quotient_${month}`] = groupRow[`guard_gmv_${month}`] / last_gmv_agv
                }
                let currentMonthBonusInfo = this.quarterBonusList.find((value) => value.group_id === row.group_id && value.month === month)
                groupRow[`bonus_${month}`] =
                    currentMonthBonusInfo && currentMonthBonusInfo.bonus != undefined && currentMonthBonusInfo.bonus != null && currentMonthBonusInfo.bonus != 0 ? currentMonthBonusInfo.bonus / 1 : 0
                let real_gmv = Number(groupRow[`real_gmv_${month}`])
                groupRow[`completion_${month}`] = real_gmv && real_gmv != 0 ? (real_gmv / Number(groupRow[`guard_gmv_${month}`])) * 100 : ''
              })
            })

            groupRow['leader_name'] = row['leader_name']
            groupRow['m_leader'] = row['m_leader']
            groupRow['last_total_profit'] = last_total_profit
            groupRow['net_profit'] = net_profit
            groupRow['last_gmv_agv'] = last_gmv_agv

            groupRow['child'] = [...mapGroup]
            // 上季度GMV均值
            //计算一季度奖金包or赋值
            let currentBonusInfo = this.departmentBonusList.find((value) => value.group_id === row.group_id)
            groupRow['bonus_quarter'] = currentBonusInfo && currentBonusInfo.bonus_quarter ? currentBonusInfo.bonus_quarter : this.$utils.calcBonusQuarter(groupRow.last_total_profit)
            //
            data.push(groupRow)
          }
        }
      })
      return data
    },
    //计算需要合计的列
    summaryColumns() {
      // let months = [...this.lastQuarterlyMonths, ...this.quarterlyMonths]
      let columns = []
      this.lastQuarterlyMonths.forEach((month) => {
        columns.push(`real_gmv_${month}`)
      })
      this.quarterlyMonths.forEach((month) => {
        columns.push(`net_profit_${month}`)
        columns.push(`guard_gmv_${month}`)
        columns.push(`real_gmv_${month}`)
        columns.push(`bonus_${month}`)
      })
      return columns
    }
  },
  data() {
    return {
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      dataList: [],
      searchCondition: {
        year: null,
        quarter: null
      },
      pageData: { page_size: 15, current_page: 1 },
      total: 0,
      currentId: null,
      dialogVisible: false,
      dialogVisibleInit: false,
      summary_columns: [], //需要合计的列表
      //当前筛选的季度奖金数据列表
      quarterBonusList: [],
      departmentBonusList: [],
      downLoading: false,
      tipList: [
        '以自然季计算，以上季度净利润总额核定本季度月奖金包上限，X为上季度净利润总额',
        '',
        '当X≤0元时，本季度月项目组奖金包上限为5000元；',
        '当0元＜X≤900000元，本季度月项目组奖金包上限为20000元；',
        '当900000元＜X≤1500000元，本季度月项目组奖金包上限为30000元；',
        '当1500000元＜X≤2400000元，本季度月项目组奖金包上限为40000元；',
        ' 当2400000元＜X≤3300000元，本季度月项目组奖金包上限为60000元；',
        '当X＞3300000元，本季度月项目组奖金包上限为80000元；',
        '特别说明1：以上净利润以项目组为单位进行计算，原则上一个项目组运营红人不超过3人；',
        '特别说明2：新项目组新红人开播，首季度以月项目组奖金包上限20000元为初始档；'
      ],
      tipCountList: [
        '1.上季平均: 上季度三个月的月实际相加，除以3',
        '2.上季度总利润:上季度三个月的净利润相加',
        '3.浮动系数: 当月保底/上季平均',
        '4.当月组奖金: (当月实际-当月保底)*10%',
        '&nbsp&nbsp4.1 当月奖金小于等于0时，取0',
        '&nbsp&nbsp4.2 当月奖金大于0，小于季度奖金包时，取计算值',
        '&nbsp&nbsp4.3 当月奖金大于等于季度奖金包时，取季度奖金包值'
      ],

      expands: [],
      tableLoading: false,
      quarterRow: {},
      rowMonth: 0,
      editDialog: false,
      calcBonus: {},
      editBonsValue: 0
    }
  },
  mounted() {
  },
  // updated() {

  //   if (data) {
  //     this.expands.push(data)
  //   }
  // },

  methods: {
    setAuthority(val) {
      return this.userPermissions.indexOf(val) > -1
    },
    expandRow(row) {
      this.$refs.tableList.toggleRowExpansion(row.group_id)
      this.expands = []
      this.expands.push(row.group_id)
      localStorage.setItem('expandData', row.group_id) //设置缓存数据，(key,value)
    },
    expandFun(expandedRows, expanded) {
      // this.expands=expanded
      if (expanded.length > 1) {
        this.$refs.tableList.toggleRowExpansion(expanded[0])
        localStorage.setItem('expandData', expandedRows.group_id) //设置缓存数据，(key,value)
      }
    },

    async exportDownload() {
      this.downLoading = true
      try {
        var isFileSaverSupported = !!new Blob()
        if (isFileSaverSupported) {
          let searchCondition = {
            year: this.searchCondition.year,
            quarter: this.searchCondition.quarter,
            is_export: true
          }
          let response = await this.$api.downEcpMonthList(searchCondition)
          let data = response.data
          let contentType = response.headers['content-type']
          let name = `${this.searchCondition.year}年-${this.searchCondition.quarter}季度绩效一览`
          let blob = new Blob([data], {
            type: contentType
          })
          FileSaver.saveAs(blob, decodeURI(name + '.xlsx'), { autoBom: true })
          this.downLoading = false
        } else {
          this.$message.warning('浏览器不支持导出文件')
          this.downLoading = false
        }
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        this.downLoading = false

        // alert(JSON.stringify(e))
      }
    },
    async editBons(row, field) {
      let postData = {
        year: row.year,
        quarter: row.quarter,
        group_id: row.group_id,
        group_name: row.group_name,
        dept_id: row.dept_id,
        dept_name: row.dept_name,
        bonus_quarter: row[field]
      }
      let info = await this.$api.updateEcpBonus(postData)
      if (info) {
        row[field] = row['bonus_quarter']
      }
      // updateEcpBonus
    },
    async editVal(row, month) {
      this.editDialog = true
      this.calcBonus = row
      this.rowMonth = month
      this.editBonsValue = Number(this.calcBonus[`bonus_${this.rowMonth}`])
      // bonus_quarter
      // 判断是奖金字段修改
      // if (field.indexOf('bonus_') > -1) {
      //   let fArr = field.split('_')
      //   let month = fArr[fArr.length - 1]
      //   let bonus = 0
      //   bonus = Number(row[field])
      //   let postData = {
      //     'year': row.year,
      //     'month': month,
      //     'quarter': row.quarter,
      //     'group_id': row.group_id,
      //     'group_name': row.group_name,
      //     'dept_id': row.dept_id,
      //     'dept_name': row.dept_name,
      //     'bonus': bonus
      //   }
      //   let info = await this.$api.saveEcpMonthBonus(postData)
      //   if (info) {
      //     row[field] = postData.bonus
      //     // this.$notify.success(`您设置了${info.group_name}项目 ${info.month}月份 奖金￥：${info.bonus}`)
      //   }
      // }
    },
    closeBefore() {
      this.editDialog = false
      this.editBonsValue = 0
    },
    async saveBonus() {
      let rowData = this.calcBonus

      let postData = {
        'year': rowData.year,
        'month': this.rowMonth,
        'quarter': rowData.quarter,
        'group_id': rowData.group_id,
        'group_name': rowData.group_name,
        'dept_id': rowData.dept_id,
        'dept_name': rowData.dept_name,
        'bonus': this.editBonsValue
      }
      let id = await this.$api.saveEcpMonthBonus(postData)

      if (id) {
        this.editDialog = false
      }
      await this.getList()
    },
    userValue(value) {
      console.log(value)
      this.editBonsValue = value.toFixed(2)
    },

    gotoKolMapper() {
      this.$router.push({ name: 'ecp_kol' })
    },
    handleAdd() {
      this.currentId = null
      this.dialogVisible = true
    },
    // eslint-disable-next-line no-unused-vars
    handleEdit(row, id, index) {
      this.currentId = id
      // this.$notify({ message: `ID:${this.currentId}` })
      this.dialogVisible = true
    },
    changeQuarter() {
      //计算季度对应的月份
    },
    // 获取当前筛选的季度的奖金列表（用于组装每个小组月度奖金）
    async getQuarterBonusData() {
      if (this.searchCondition.year && this.searchCondition.quarter) {
        //获取月为单位的奖金
        let { list } = await this.$api.getEcpQuarterBonus({
          year: this.searchCondition.year,
          quarter: this.searchCondition.quarter
        })
        this.quarterBonusList = list
        //获取一季度的部门的奖金包额度
        let data = await this.$api.getEcpBonus({
          year: this.searchCondition.year,
          quarter: this.searchCondition.quarter
        })
        this.departmentBonusList = data

      }
    },

    async getList() {
      this.tableLoading = true
      //奖金数据（先查询）
      await this.getQuarterBonusData()
      //绩效数据
      this.dataList = []
      let searchCondition = Object.assign(
        this.searchCondition,
        {
          page_size: 100,
          current_page: 1
        },
        { order: { dept_name: 'asc', group_name: 'asc', id: 'asc' } }
      )
      let { list, pages } = await this.$api.getEcpQuarterList(searchCondition)
      this.dataList = [...list]
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getEcpQuarterList(searchCondition)
        this.dataList = [...this.dataList, ...list]
      }
      let data = localStorage.getItem('expandData')
      if (data) {
        this.expands = []
        this.expands.push(data)
      }
      this.tableLoading = false
    },
    searchEvent(val) {
      this.searchCondition = val
      this.getList()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },

    moneyFormat(val) {
      return this.$utils.numberFormat(val, 2)
    },
    numFormat(val) {
      return this.$utils.numberFormat(val, 0)
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = ''
          return
        }
        if (this.summaryColumns.indexOf(column.property) > -1) {
          const values = data.map((item) => Number(item[column.property]))
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' '
          } else {
            sums[index] = 'N/A'
          }

          switch (column.property) {
            default:
              sums[index] = '¥ ' + this.moneyFormat(sums[index])
              break
          }
        }
      })

      return sums
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      if (row.is_group) {
        return 'group-row pointer'
      } else if (row.is_dept) {
        return 'dept-row pointer'
      }
      return 'pointer'
    }
  }
}
</script>

<style lang="scss" scoped>

.panel-box {
  border: #0c0c0c 1px dashed;
  display: inline;
}

.bonus-quarter {
  /*border: #0C0C0C 1px dashed;*/
  padding: 2px;
  /*background: #0C0C0C;*/
  font-size: 1.1em;
  /*color: #fefefe;*/
}

.bonus {
  /*border: #0C0C0C 1px dashed;*/
  padding: 2px;
  /*background: #0C0C0C;*/
  font-size: 1.1em;
  /*color: #f2f2f2;*/
}

::v-deep.ecp-table {
}

::v-deep.ecp-table .el-table thead {
  background: #eee;
  color: #666;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

::v-deep.ecp-table .el-table__header-wrapper .el-table__header thead tr th {
  background: #ebeef5;
  padding: 0 0 !important;
}

::v-deep.ecp-table .el-table__fixed-header-wrapper .el-table__header thead tr th {
  background: rgba(248, 248, 248, 1);
  padding: 0 0 !important;
}

::v-deep.ecp-table th,
.ecp-table td {
  /*padding: 4px 0;*/
}

::v-deep.ecp-table div.el-table td .cell {
  height: auto !important;
  line-height: 20px;
  font-weight: normal;
  font-size: 12px;
}

/*el-table__cell*/
::v-deep.ecp-table thead th .cell {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 800;
  color: #0c0c0c;
}

::v-deep.ecp-table-child thead tr th .cell {
  height: 30px !important;
  line-height: 30px !important;
  font-size: 12px !important;
  /*font-weight: 800 !important; !**!*/
  color: #0c0c0c !important;
}

::v-deep.ecp-table thead th.is-leaf .cell {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 800;
  color: #0c0c0c;
}

::v-deep.ecp-table-child thead th.is-leaf .cell {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  font-weight: normal !important;
  color: #0c0c0c;
}

::v-deep.ecp-table-child .el-table__header-wrapper .el-table__header thead tr th {
  background: #fbf2f5;
  padding: 0 0 !important;
}

::v-deep.ecp-table .el-table thead tr,
.ecp-table .el-table thead th {
  background: rgb(255, 255, 255) !important;
  padding: 10px 0 !important;
  color: rgba(50, 50, 50, 1);
  font-size: 14px;
  font-weight: normal;
}

::v-deep.ecp-table .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #ebeef5;
}

.ecp-tip {
  border: #0c0c0c 1px dashed;
  background: rgba(255, 249, 251, 0.98);
  border-radius: 10px;
  padding: 5px 10px;
}

.infoBox {
  margin-top: 20px;
  font-size: 12px;
  color: #909399;

  line-height: 20px;
}

.dialogBox {
  border-right: 1px dashed #ff3176;


  .boundInfo {
    padding: 10px;

    > span {
      display: block;
      margin: 2px 0;

      > i {
        color: #ff3176;
      }
    }
  }
}
</style>

