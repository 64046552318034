<template>
  <div class='ecp-performance' v-if='userPermissions.indexOf("ecp_team")>-1'>
    <el-tabs :tab-position='tabPosition' class='performance-tab'>
      <!--      <el-tab-pane label='项目组绩效' v-if='userPermissions.indexOf("ecp_team_list")>-1'>-->
      <TeamView/>
      <!--      </el-tab-pane>-->
      <!--      <el-tab-pane label='招商部绩效' v-if='userPermissions.indexOf("ecp_merchant_list")>-1'>-->
      <!--      <el-tab-pane label='招商部绩效' v-if='userPermissions.indexOf("ecp_merchant_list")>-1'>-->
      <!--        <MerchantView />-->
      <!--      </el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script>
import TeamView from '@/pages/commerce/performance/TeamView'
import MerchantView from '@/pages/commerce/performance/MerchantView'
import { mapGetters } from 'vuex'

export default {
  name: 'performanceIndex',
  components: { MerchantView, TeamView },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      tabPosition: 'left'
    }
  }
}
</script>

<style scoped>
.ecp-performance{
  margin-top: 10px;
}
</style>
