<template>
  <div style="margin: 0 auto">
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' width='90%' :title='dialogTitle'>
      <el-form ref='elForm' :model='formData' label-width='100px'>
        <el-row :gutter='10'>
          <el-col :span='12'>
            <div class='card'>
              <div class='card-title'>基本信息<span v-if='formData.id'>(ID:{{ formData.id }})</span></div>
              <div class='card-box'>
                <el-form :inline='false' label-position='right'>
                  <el-form-item label='红人昵称：'>
                    <span v-if="!formData.nickname&&!id">
                      <el-select     v-model='kolMapperId'
                                     clearable filterable style='width: 200px;' @change='selectMapper'>
                        <el-option v-for='(option,index) in kolMapperList' :key='index' :value='option.id'
                                   :label='option.nickname'>
                          <span style='float: left'>{{ option.nickname }}/{{ option.leader_name }}</span>
                          <span style='float: right'>{{ option.dept_name }}/{{ option.group_name }}</span>
                        </el-option>
                      </el-select>
                      <el-button type='text' @click='getKolMappers' icon='el-icon-refresh' title='刷新红人关联'
                                 style="margin-left: 10px" :loading='loadingMapper'></el-button>
                      <el-button type='text' icon='el-icon-plus' title='添加红人关联'
                                 @click='handleAddMapper'></el-button>
                    </span>
                    <span>{{ formData.nickname || '' }}</span>
                    <el-button type='text' icon='el-icon-circle-close' @click='formData.nickname=false'
                               style="margin-left:5px" v-if='formData.nickname&&!id'></el-button>
                    <el-button type='text' icon='el-icon-edit' @click='handleEditMapper'
                               v-if='formData.nickname'></el-button>
                  </el-form-item>
                  <el-form-item label='项目部&组：'>
                    <span>{{ formData.dept_name || '' }}/{{ formData.group_name || '' }}</span>
                  </el-form-item>
                  <el-form-item label='项目组长/副组长：'>
                    <span>{{ formData.leader_name || '' }}</span>
                  </el-form-item>
                  <el-form-item label='管理负责人：'>
                    <span>{{ formData.m_leader || '' }}</span>
                  </el-form-item>
                  <el-form-item>
                    <el-button type='primary' @click='handleAdd' :loading='loadingCommit' :disabled='loadingCommit'>保存</el-button>
<!--                    <el-button type='danger' @click='handleDel' icon='el-icon-delete' :loading='loadingCommit'-->
<!--                               :disabled='loadingCommit' v-if='this.formData.id'>删除</el-button>-->
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-col>
          <el-col :span='12'>
            <div class='card'>
              <div class='card-title'>上季度数据</div>
              <div class='card-box'>
                <el-form :inline='false' label-position='right'>
                  <div style='display: flex;flex-direction: row'>
                    <div style='width: 100%'>
                      <el-form-item label='上季度总利润：'>
                        <el-input v-model='formData.last_total_profit'
                                  style='width: 200px;display: inline-block;margin-right: 10px'
                                  clearable
                                  placeholder='保留2位小数位'></el-input>
                        平均:{{
                          $utils.numberFormat((100 * (formData.last_gmv_first / 1 + formData.last_gmv_second / 1 + formData.last_gmv_third / 1) / 3) / 100, 2)
                        }}

                      </el-form-item>
                      <el-form-item :label='`${lastQuarterlyMonths[0]}月GMV：`'>
                        <el-input v-model='formData.last_gmv_first' style='width: 225px' clearable
                                  placeholder='保留2位小数位' oninput="value=value.replace(/[^\d^\.^\-]/g,'')"></el-input>
                      </el-form-item>
                      <el-form-item :label='`${lastQuarterlyMonths[1]}月GMV：`'>
                        <el-input v-model='formData.last_gmv_second' style='width: 225px' clearable
                                  placeholder='保留2位小数位' oninput="value=value.replace(/[^\d^\.^\-]/g,'')"></el-input>
                      </el-form-item>
                      <el-form-item :label='`${lastQuarterlyMonths[2]}月GMV：`'>
                        <el-input v-model='formData.last_gmv_third' style='width: 225px' clearable
                                  placeholder='保留2位小数位' oninput="value=value.replace(/[^\d^\.^\-]/g,'')"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type='primary' @click='handleAdd' :loading='loadingCommit'
                                   :disabled='loadingCommit||!formData.id'>
                          保存
                        </el-button>
                      </el-form-item>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>

          </el-col>
        </el-row>
        <!--        月份绩效数据-->

        <el-row :gutter='10'>
          <el-col :span='8' v-for='(month,index) in quarterlyMonths' :key='index'>
            <div class='card'>
              <div class='card-title'>{{ month }}月份数据</div>
              <div class='card-box'>
                <TeamSingleForm :quarter-data='formData' :month='month' :data.sync='teamRows[month]'
                                v-on="$listeners" v-bind="$attrs"
                                :average="(100 * (formData.last_gmv_first / 1 + formData.last_gmv_second / 1 + formData.last_gmv_third / 1) / 3) / 100"
                                v-if='teamRows[month]'/>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <!--    添加  -->
    <KolMapperCreateEdit :visible.sync='dialogVisible' :id='kolMapperId' @saved='getKolMappers' />
  </div>
</template>

<script>
import TeamSingleForm from '@/pages/commerce/performance/TeamSingleForm'
import KolMapperCreateEdit from '@/pages/commerce/kol_mapper/KolMapperCreateEdit'

export default {
  name: 'CreateEditTeamData',
  components: { KolMapperCreateEdit, TeamSingleForm },
  props: {
    id: {
      type: [String, Number],
      default() {
        return null
      }
    },
    searchCondition: {
      type: Object,
      default() {
        return {
          year: null, quarter: null
        }
      }
    },
  },
  computed: {
    dialogTitle() {
      return `${this.id ? '修改（ID：' + this.id + '）' : '添加'} 项目组绩效(${this.searchCondition.year}年第${this.searchCondition.quarter}季度)`
    },
    // 本季度月份
    quarterlyMonths() {
      let months = []
      if (this.searchCondition.quarter > 0)
        months = [this.searchCondition.quarter * 3 - 2, this.searchCondition.quarter * 3 - 1, this.searchCondition.quarter * 3]

      return months
    },
    //上季度月份
    lastQuarterlyMonths() {
      let lastMonths = []
      if (this.searchCondition.year && this.searchCondition.quarter) {
        // 计算上季度月份数据
        let lastQuarter = this.searchCondition.quarter - 1
        if (lastQuarter === 0) {
          lastQuarter = 4
        }
        lastMonths = [lastQuarter * 3 - 2, lastQuarter * 3 - 1, lastQuarter * 3]
      }

      return lastMonths
    }
  },
  data() {
    return {
      dialogVisible: false,
      loadingCommit: false,
      formData: {
        year: null,
        quarter: null,
        mapper_id: null,
        artist_id: '',
        nickname: '',
        leader_id: '',
        leader_name: '',
        dept_id: '',
        dept_name: '',
        group_id: '',
        group_name: '',
        m_leader_id: '',
        m_leader: '',
        last_total_profit: null,
        last_gmv_first: null,
        last_gmv_second: null,
        last_gmv_third: null
      },
      teamRows: {},
      statusOption: [
        { label: '有效', value: 'Y' },
        { label: '无效', value: 'N' }
      ],
      loadingMapper: false,
      kolMapperId: null,
      kolMapperList: [],
      profitRange: [900000, 1500000, 2400000, 3300000],
      pageData: { page_size: 999, current_page: 1 },

    }
  },
  methods: {
    onOpen() {
      this.getKolMappers()
      this.loadingCommit = false
      this.formData = {
        year: null,
        quarter: null,
        mapper_id: null,
        artist_id: '',
        nickname: '',
        leader_id: '',
        leader_name: '',
        dept_id: '',
        dept_name: '',
        group_id: '',
        group_name: '',
        m_leader_id: '',
        m_leader: '',
        last_total_profit: null,
        last_gmv_first: null,
        last_gmv_second: null,
        last_gmv_third: null
      }
      let teamRows = {}
      this.quarterlyMonths.forEach((month) => {
        teamRows[month] = {
          artist_id: this.formData.artist_id,
          nickname: this.formData.nickname,
          year: this.searchCondition.year,
          quarter: this.searchCondition.quarter,
          month: month,
          pid: null,
          quotient: null,
          net_profit: null,
          guard_gmv: null,
          real_gmv: null
        }
      })
      this.teamRows = { ...teamRows }
      this.getAInfo()
    },
    onClose() {

    },
    close() {
      this.$emit('update:visible', false)
    },
    async getAInfo() {
      if (this.id) {
        let { info } = await this.$api.getEcpQuarterInfo(this.id)
        this.formData = { ...info }
        // 加载 【月度目标和实际情况(list 大小最大为3)】
        let { list } = await this.$api.getEcpMonthList({ pid: this.id, page_size: 10, current_page: 1 })
        let monthRows = {}
        this.quarterlyMonths.forEach((month) => {
          // 若已保存过， 则使用上次保存的数据
          let savedData = list.find(value => value.month === month)
          if (savedData) {
            monthRows[month] = { ...savedData }
          } else {
            monthRows[month] = {
              artist_id: this.formData.artist_id,
              nickname: this.formData.nickname,
              year: this.searchCondition.year,
              quarter: this.searchCondition.quarter,
              month: month,
              pid: null,
              quotient: null,
              net_profit: null,
              guard_gmv: null,
              real_gmv: null
            }
          }

        })
        this.teamRows = { ...monthRows }
      }
    },
    //获取红人关联信息
    async getKolMappers() {
      this.loadingMapper = true
      // 临时从缓存获取
      // this.getCache()
      let { list } = await this.$api.getKolMapperList(this.pageData)
      this.kolMapperList = list
      setTimeout(() => {

        this.loadingMapper = false
      }, 500)
    },
    // 创建关联
    handleAddMapper() {
      this.dialogVisible = true
      this.kolMapperId = null
    },
    //修改现有的关联
    handleEditMapper() {
      this.dialogVisible = true
      this.kolMapperId = this.formData.mapper_id
    },
    getCache() {
      let dataListStr = localStorage.getItem('kol_mappers')
      this.kolMapperList = dataListStr ? JSON.parse(dataListStr) : []
    },
    // 选择红人关联
    selectMapper(id) {
      let kolMapper = this.kolMapperList.find(value => value.id === id)
      this.formData.mapper_id = kolMapper ? kolMapper.id : null
      this.formData.artist_id = kolMapper ? kolMapper.artist_id : null
      this.formData.nickname = kolMapper ? kolMapper.nickname : null
      this.formData.dept_id = kolMapper ? kolMapper.dept_id : null
      this.formData.dept_name = kolMapper ? kolMapper.dept_name : null
      this.formData.group_id = kolMapper ? kolMapper.group_id : null
      this.formData.group_name = kolMapper ? kolMapper.group_name : null
      this.formData.leader_id = kolMapper ? kolMapper.leader_id : null
      this.formData.leader_name = kolMapper ? kolMapper.leader_name : null
      this.formData.m_leader = kolMapper ? kolMapper.m_leader : null
      this.formData.m_leader_id = kolMapper ? kolMapper.m_leader_id : null
    },
    //删除季度绩效
    async handleDel() {
      let msg = `删除[${this.formData.nickname}]整个季度(${this.searchCondition.year}年${this.searchCondition.quarter}月)数据？`
      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let info = await this.$api.deleteEcpQuarter({id:this.formData.id})
        if (info) {
          this.msgSuccess('删除成功')
          this.close()
        }
      })

    },
    // 保存
    async handleAdd() {
      this.loadingCommit = true
      let postData = Object.assign(this.formData, {
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter
      })

      try {
        let id = await this.$api.saveEcpQuarter(postData)
        if (id) {
          this.formData.id = id
        }
      } finally {
        this.loadingCommit = false

      }
    },
    //快捷设置上季度总利润
    qkSetProfit(val) {
      this.formData.last_total_profit = val / 1
      // this.calcBonusQuarter()
    },
    calcBonusQuarter() {
      let bonusQuarter = 0
      // 计算一季度奖金包
      let total_profit = this.formData.last_total_profit / 1
      if (total_profit <= 0) {
        bonusQuarter = 5000

      } else if (total_profit <= 900000) {
        bonusQuarter = 20000
      } else if (total_profit <= 1500000) {
        bonusQuarter = 30000
      } else if (total_profit <= 2400000) {
        bonusQuarter = 40000
      } else if (total_profit <= 3300000) {
        bonusQuarter = 60000
      } else {
        bonusQuarter = 80000
      }
      // console.log('bonus_quarter:', bonusQuarter)
      //新红人开播
      this.formData.bonus_quarter = bonusQuarter

      return bonusQuarter

    }
  },
  mounted() {
    this.onOpen()
  }
}
</script>

<style scoped>

.card {
  line-height: 400px;
  width: 100%;

}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 5px;
}

.card-box {
  padding: 10px 20px;
  line-height: 350px;
  height: 230px;
  border: #2c2727 1px dashed;
  margin-bottom: 20px;
}
</style>
