<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' width='70%' :title='dialogTitle'
               :append-to-body='true'>
      <div>
        <el-row>
          <el-col :span='20'>
            <el-form inline size='mini'>
              <el-form-item>
                <el-button type='primary' @click='dialogVisible=true' icon='el-icon-plus'>新增红人关联</el-button>
              </el-form-item>
              <el-form-item>
                <el-button type='' @click='getList' icon='el-icon-tickets'>刷新列表</el-button>
              </el-form-item>
              <el-form-item>
                <el-button type='primary' @click='handleInitCheck' :disabled='multiple' icon='el-icon-refresh'>
                  绩效初始化({{ initTotal }}/{{ selections.length }})
                </el-button>
              </el-form-item>
              <el-form-item>
                <el-link type='primary' @click='gotoKolMapper' icon='el-icon-setting'>管理项目组红人关联</el-link>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <div class='default-table'>
          <el-table height="900"
                    border :data='dataList' @selection-change='handleSelectionChange'>
            <el-table-column
                type='selection'
                width='55'>
            </el-table-column>
            <el-table-column type='index' label='序号' align='center' width='55'></el-table-column>
            <!--            <el-table-column prop='id' label='ID' align='center' width='100'></el-table-column>-->
            <el-table-column prop='nickname' label='红人' align='center' width='200'></el-table-column>
            <el-table-column label='项目组长/副组长' prop='leader_name' width='160' align='center'></el-table-column>
            <el-table-column label='项目组' prop='dept_name' width='260' align='center'>
              <template slot-scope='{row}'>
                <span>{{ row.dept_name || '-' }}/{{ row.group_name || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='项目组负责人' prop='m_leader' width='160' align='center'></el-table-column>
            <el-table-column label='操作' align='center' width="160">
              <template slot-scope='{row}'>
                <el-button type='text' @click='handleInit(row)' icon='el-icon-refresh'>初始化</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!--      分页-->
          <pagination :total.sync='total' :limit.sync='pageData.page_size' :page.sync='pageData.current_page'
                      @pagination='getList' />
        </div>
      </div>
    </el-dialog>
    <!--    添加  -->
    <KolMapperCreateEdit :visible.sync='dialogVisible' @saved='getList' />
  </div>
</template>

<script>

import KolMapperCreateEdit from '@/pages/commerce/kol_mapper/KolMapperCreateEdit'

export default {
  name: 'TeamDataInit',
  components: { KolMapperCreateEdit },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {
          year: null, quarter: null
        }
      }
    }
  },
  computed: {
    dialogTitle() {
      return `初始化项目组绩效(${this.searchCondition.year}年第${this.searchCondition.quarter}季度)`
    }
  },
  data() {
    return {
      currentId: null,
      dialogVisible: false,
      dataList: [],
      pageData: { page_size: 15, current_page: 1 },
      total: 0,
      selections: [],
      // 非多个禁用
      multiple: true,
      initTotal: 0
    }
  },
  methods: {
    onOpen() {
      this.getList()
    },
    onClose() {

    },
    close() {
      this.$emit('update:visible', false)
    },
    //加载列表
    async getList() {
      // this.getCache()
      let searchCond = {}
      let { list, pages } = await this.$api.getKolMapperList(Object.assign(searchCond, this.pageData))
      this.dataList = list
      // console.log(pages)
      this.total = pages.total
      this.pageData.page_size = pages.page_size || 15
      this.pageData.current_page = pages.current_page || 15
    },
    async handleInit(row) {
      delete row.id
      let postData = {
        mapper_id: row.id,
        artist_id: row.artist_id,
        nickname: row.nickname,
        leader_name: row.leader_name,
        leader_id: row.leader_id,
        m_leader: row.m_leader,
        m_leader_name: row.m_leader_name,
        dept_id: row.dept_id,
        dept_name: row.dept_name,
        group_id: row.group_id,
        group_name: row.group_name,
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter
      }

    let data=  await  this.$api.saveEcpQuarter(postData)
      if(data){
        this.$notify.success('红人初始化成功')
      }
    },
    handleSelectionChange(selection) {
      this.selections = selection
      this.multiple = !selection.length
    },
    async handleInitCheck() {
      let data = this.selections.map((row) => {
        return {
          mapper_id: row.id,
          artist_id: row.artist_id,
          nickname: row.nickname,
          leader_name: row.leader_name,
          leader_id: row.leader_id,
          m_leader: row.m_leader,
          m_leader_name: row.m_leader_name,
          m_leader_id: row.m_leader_id,
          dept_id: row.dept_id,
          dept_name: row.dept_name,
          group_id: row.group_id,
          group_name: row.group_name,
          year: this.searchCondition.year,
          quarter: this.searchCondition.quarter

        }
      })
      let { success_total, fail_list } = await this.$api.batchSaveEcpQuarter({ 'rows': data })

      if (fail_list && fail_list.length > 0) {
        let nameArr = fail_list.map(_ => {
          return `红人：${_.nickname},项目组：${_.group_name},组长：${_.leader_name}`
        })
        this.$notify.warning(`初始化：${data.length},成功:${success_total},失败：【${fail_list.length}】,分别是：${nameArr.join('、')}`)
        setTimeout(() => {
          this.close()
        }, 1000)
      } else {
        this.$notify.success(`成功添加[${data.length}]条数据`)
        this.initTotal=data.length
        setTimeout(() => {
          this.close()
        }, 500)
      }
    },
    gotoKolMapper() {
      this.$router.push({ name: 'ecp_kol' })
    }
  }
}
</script>

<style scoped>

</style>
