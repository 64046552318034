<template>
  <div>
    <el-form :model='formData'>
      <el-form-item label='浮动系数：'>
        <el-input :placeholder='`${currentMonth}月浮动系数（保留2位小数位）`' v-model='formData.quotient' style='width: 300px'
                  clearable oninput="value=value.replace(/[^\d^\.^\-]/g,'')">
          <el-button slot='append' type='primary' @click='calcQuotient'>计算 <i class='el-icon-info'
                                                                              :title='`浮动系数=${currentMonth}月目标/上季平均`'></i>
          </el-button>
        </el-input>
      </el-form-item>
      <el-form-item :label='`${currentMonth}月目标：`' prop='guard_gmv'>
        <el-input :placeholder='`${currentMonth}月目标（保留2位小数位）`' v-model='formData.guard_gmv' style='width: 300px'
                  clearable oninput="value=value.replace(/[^\d^\.^\-]/g,'')" @change='calcQuotient'>
          <el-button slot='append' type='primary' @click='setGuardGmv'
                     :title='`同步上季度均值：${lastGmvAgv} 为本季度${currentMonth}月目标`'>上季均值
          </el-button>
        </el-input>
      </el-form-item>
      <el-form-item :label='`${currentMonth}月实际：`'>
        <el-input :placeholder='`${currentMonth}月实际（保留2位小数位）`' v-model='formData.real_gmv' style='width: 220px'
                  clearable oninput="value=value.replace(/[^\d^\.^\-]/g,'')" @change='calcBonus' />
        <span
          style='margin-left: 10px'>达成率：{{ Math.round(1000 * (formData.real_gmv ? formData.real_gmv / formData.guard_gmv : 0)) / 10
          }} %</span>
      </el-form-item>
      <el-form-item :label='`${currentMonth}月奖金：`'>
        <el-input :placeholder='`${currentMonth}月奖金（保留2位小数位）`' v-model='formData.bonus' style='width: 300px' clearable
                  oninput="value=value.replace(/[^\d^\.^\-]/g,'')">
          <el-button slot='append' type='primary' @click='calcBonus'>计算奖金</el-button>
        </el-input>
        <div v-if='showTotalBonus' style='border: #0C0C0C 1px dashed;padding: 5px;border-radius: 5px;margin-top: 5px'>
          <b>团队情况汇总</b><br />
          <div v-if='positionLabel===1'>
            <span class='title-label'>目标：</span> <span class='money'>{{ $utils.numberFormat(team_data.team_guard_gmv, 2)
            }}</span>
            &nbsp;&nbsp;
            <span class='title-label' v-if='positionLabel===1'>实际完成：</span> <span class='money'>
          {{ $utils.numberFormat(team_data.team_real_gmv, 2) }}</span>
            <span class='title-label' v-if='positionLabel===1'>达成率：</span> <span class='ratio'>
          {{ $utils.numberFormat(team_data.team_quotient * 100, 2) }} </span>

          </div>
          <div>
            <span class='title-label'>招商专员奖金总额：</span> <span class='money'>
          {{ $utils.numberFormat(total_bonus, 2) }}</span>
            &nbsp;&nbsp;
            <div v-if='positionLabel===1' style='display: inline-block'>
              <span class='title-label'>团队完成系数：</span>{{ ratio }} <span></span>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item style='text-align: center'>
        <!--        <el-button type='warning' disabled>发布{{ currentMonth }}月奖金</el-button>-->
        <el-button type='primary' @click='onsubmit' :disabled='loadingCommit||!quarterData.id' :loading='loadingCommit'>
          {{ loadingCommit ? '提交中...' : '保存' }}
        </el-button>
        <el-button icon='el-icon-view' @click='handleTotalBonus' v-if='showTotalBonus'>招商专员奖金总额</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'MerchantSingleForm',
  props: {
    month: {
      type: [Number, String]
    },
    data: {
      type: Object,
      default() {
        return {
          artist_id: null,
          nickname: '',
          year: null,
          month: null,
          quotient: null,
          net_profit: null,
          guard_gmv: null,
          real_gmv: null,
          bonus: null
        }
      }
    },
    quarterData: {
      type: Object,
      default() {
        return {}
      }
    },
    positionLabel: {
      type: [Number, String],
      default() {
        return null
      }
    },
    lastGmvAgv: {
      type: Number,
      default() {
        return null
      }
    }
  },
  computed: {
    //是否展示转员奖金总额
    showTotalBonus() {
      return this.positionLabel === 1 || this.positionLabel === 3
    },
    formData: {
      get() {
        return this.data
      },
      set(val) {
        this.$emit('update:data', val)
      }
    },
    currentMonth: {
      get() {
        return this.month
      },
      set(val) {
        this.$emit('update:month', val)
      }
    }
  },
  data() {
    return {
      loadingCommit: false,
      total_bonus: 0,
      team_data: {
        'team_quotient': 0,
        'team_real_gmv': 0,
        'team_guard_gmv': 0
      },
      ratio: 0
    }
  },
  watch: {
    lastGmvAgv: {
      immediate: true,
      handler() {
        this.calcQuotient()
      }
    }
  },
  methods: {
    async onsubmit() {
      this.loadingCommit = true
      let postData = Object.assign(this.formData, {
        year: this.quarterData.year,
        pid: this.quarterData.id,
        artist_id: this.quarterData.artist_id,
        nickname: this.quarterData.nickname,
        quarter: this.quarterData.quarter
      })
      let id = await this.$api.saveEcpMerchantMonth(postData)
      if (id)
        this.formData.id = id

      setTimeout(() => {
        this.loadingCommit = false
      }, 500)

    },
    async calcBonus() {
      // console.log(this.data)
      if (this.positionLabel === 2) {
        this.formData.bonus = (this.formData.real_gmv - this.formData.guard_gmv) * 0.03 * 0.65
        this.$notify.info(`招商专员：（(1月实际)-(1月目标)）* 3% *65%,奖金为：(${this.formData.real_gmv}-${this.formData.guard_gmv})*0.03*0.65 = ${this.formData.bonus}`)
      } else if (this.positionLabel === 1) {
        this.$notify.info(`招商负责人（总监）：招商专员奖金总额 * 25% * 团队完成系数`)
        // 总监奖金计算
        let total_bonus = await this.handleTotalBonus()

        let ratio = await this.handleTeamQuotient()
        this.formData.bonus = total_bonus / 0.65 * 0.25 * ratio
        // this.formData.quotient = team_quotient
      } else if (this.positionLabel === 3) {
        // 总监奖金计算
        let total_bonus = await this.handleTotalBonus()
        this.formData.bonus = Math.round(100 * total_bonus / 10) / 100
        this.$notify.success(`助理奖金：${this.formData.bonus}`)
      }
      // this.$notify.success(`计算奖金：${this.data.year}年${this.data.month}月份,职位：${this.positionLabel}`)
    },
    calcQuotient() {
      this.formData.quotient = Math.round(100 * (this.lastGmvAgv ? this.formData.guard_gmv / this.lastGmvAgv : 0)) / 100
      // console.log(`浮动系数( ${this.formData.quotient})=${this.currentMonth}月目标(${this.formData.guard_gmv})/上季平均(${this.lastGmvAgv})`)
    },
    setGuardGmv() {
      this.formData.guard_gmv = Math.round(100 * this.lastGmvAgv) / 100
      this.$notify.success(`设置本月目标为上季度均值：${this.lastGmvAgv}`)
      this.calcQuotient()
    },
    /**
     *
     * @returns {Promise<number>}
     */
    async handleTotalBonus() {
      if (this.positionLabel === 1 || this.positionLabel === 3) {
        let { total_bonus } = await this.$api.getEcpMerchantTotalBonus({
          year: this.quarterData.year,
          quarter: this.quarterData.quarter,
          month: this.month
        })
        this.total_bonus = total_bonus / 1
        return total_bonus / 1
      }
      return 0
    },
    /**
     *团队目标达成率-总监奖金计算
     */
    async handleTeamQuotient() {
      if (this.positionLabel === 1) {
        let team_data = await this.$api.getEcpMerchantTeamQuotient({
          year: this.quarterData.year,
          quarter: this.quarterData.quarter,
          month: this.month
        })
        this.team_data.team_quotient = team_data ? team_data.team_quotient:0
        this.team_data.team_real_gmv = team_data ? team_data.team_real_gmv:0
        this.team_data.team_guard_gmv = team_data ? team_data.team_guard_gmv:0
        this.ratio = this.calcTeamQuotient()
        // return this.team_data.team_quotient ? this.team_data.team_quotient / 1 : 0
      }
      return this.ratio
    },
    calcTeamQuotient() {
      let ratio_val = this.team_data ? this.team_data.team_quotient / 1 : 0
      if (ratio_val >= 0.8) {
        return 1
      } else if (ratio_val >= 0.5) {
        return ratio_val
      } else {
        return 0
      }
    }
  }
}
</script>

<style scoped>
.title-label {
  font-weight: bold;
  margin-left: 20px;
}
</style>