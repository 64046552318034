<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' width='1200px' modal-append-to-body
               :title='dialogTitle'>
      <el-form :model='formData'>
        <el-form-item label='选择部门：'>
          <EcpDeptSelect ref='refEcpDeptSelect' @handleSelect='selectDept' />

        </el-form-item>
        <el-form-item label='部门成员：'>
          <div style='display: block;'>
            <span v-if='dept_group.name'> 『{{ dept_group.name || '' }}』</span>
            <el-checkbox v-model='checkAll' @change='handleCheckAll'>选中『在职人员』</el-checkbox>
            <!--            <el-button type='primary' @click='defaultSelected' icon='el-icon-check'>选中『在职人员』</el-button>-->
            <br />
            <div class='member-box' v-if='dept_group.members.length>0'>
              <el-checkbox-group v-model='formData.leader_ids' @change='handleChange'>
                <el-checkbox v-for='(member,index) in dept_group.members' :key='member.userid'
                             :label='member'>
                  <span :title='`钉钉用户ID：${member.userid}`'>  {{ index + 1 }}）{{ member.name }}</span>
                  <span v-if='member.position'> 『{{ member.position }}』</span>
                  <span v-if="member.is_leave==='Y'" style='color: #F56C6C'>({{ member.is_leave_alias }})</span>
                  <span v-else>({{ member.is_leave_alias }})</span>
                </el-checkbox>
              </el-checkbox-group>
            </div>

          </div>
        </el-form-item>
        <el-form-item label='上级部门：'>
          <div style='display: block;'>
            <span v-if='dept_manage.name'> 『{{ dept_manage.name || '' }}』</span>
            <br />
            <div class='member-box' v-if='dept_manage.members.length>0'>
              <el-checkbox-group v-model='formData.m_leader_ids' @change='handleChange'>
                <el-checkbox v-for='(member,index) in dept_manage.members' :key='member.userid'
                             :label='member'>
                  <span :title='`钉钉用户ID：${member.userid}`'> {{ index + 1 }}） {{ member.name }}</span>
                  <span v-if='member.position'> 『{{ member.position }}』</span>
                  <span v-if="member.is_leave==='Y'" style='color: #F56C6C'>({{ member.is_leave_alias }})</span>
                  <span v-else>({{ member.is_leave_alias }})</span>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <el-row style='margin-bottom: 10px'>
        <el-col :span='24'>
          <el-progress :text-inside='true' :stroke-width='15' :percentage='percentage'></el-progress>
          <div style='text-align: center;font-size: 14px;margin-top: 10px'>
            <b>进度：</b><span>{{ this.initMembers.length }} / {{ this.selectMembers.length }}</span>
          </div>
        </el-col>
      </el-row>
      <div solt='footer' style='text-align: center'>
        <el-row>
          <el-col :span='24'>
            <el-button type='primary' size='medium' @click='onSubmit' icon='el-icon-refresh' :disabled='loadingCommit'
                       v-if="initMembers.length!=selectMembers.length " :loading='loadingCommit'>
              初始化
            </el-button>
            <b v-else>
              <el-button type='primary' size='medium' @click='close' style="margin-right: 10px" icon='el-icon-check'>
                完成,关闭页面
              </el-button>
            </b>

            <el-button @click='close' size='medium'>取消</el-button>
          </el-col>

        </el-row>


      </div>
    </el-dialog>
  </div>
</template>

<script>


import EcpDeptSelect from '@/pages/commerce/kol_mapper/EcpDeptSelect'

export default {
  name: 'MerchantMemberInit',
  components: { EcpDeptSelect },
  computed: {
    dialogTitle() {
      return `初始化${this.deptName}绩效数据：部门成员数据来自钉钉(${this.searchCondition.year}年第${this.searchCondition.quarter}季度)`
    },
    percentage() {
      let percentage = 0
      if (this.selectMembers.length > 0) {
        percentage = Math.ceil(100 * this.initMembers.length / this.selectMembers.length)
      }
      if (percentage > 100)
        percentage = 100

      return percentage
    }
  },
  props: {
    deptName: {
      type: String,
      default() {
        return '招商部'
      }
    },
    searchCondition: {
      type: Object,
      default() {
        return {
          year: null, quarter: null
        }
      }
    }
  },
  data() {
    return {
      loadingCommit: false,
      formData: {
        leader_ids: [],
        m_leader_ids: [],
        dept_id: null,
        dept_name: null,
        leader_id: null,
        leader_name: null,
        m_leader_id: null,
        m_leader: null
      },
      dept_group: {
        dpt_id: '',
        name: '',
        members: []
      },
      dept_manage: {
        dpt_id: '',
        name: '',
        members: []
      },
      labelMap: [
        { position: '总监', position_label: 1 },
        { position: '招商专员', position_label: 2 },
        { position: '招商助理', position_label: 3 },
        { position: '采购助理', position_label: 3 }
      ],
      checkAll: false,
      selectMembers: [],//勾选的成员
      initMembers: []//已初始化的
    }
  },
  methods: {
    onOpen() {
      this.loadingCommit = false
      this.dept_group = {
        dpt_id: '',
        name: '',
        members: []
      }
      this.dept_manage = {
        dpt_id: '',
        name: '',
        members: []
      }
      this.formData = {
        leader_ids: [],
        m_leader_ids: [],
        dept_id: null,
        dept_name: null,
        leader_id: null,
        leader_name: null,
        m_leader_id: null,
        m_leader: null
      }
      if (this.$refs['refEcpDeptSelect'])
        this.$refs['refEcpDeptSelect'].init()

      this.$notify.success('默认选择：招商部')
      this.changeDept()
    },
    onClose() {

    },
    close() {
      this.$emit('update:visible', false)
    },
    changeDept() {
      // this.$notify.success('选择默认部门')
      setTimeout(() => {
        if (this.$refs['refEcpDeptSelect'])
          this.$refs['refEcpDeptSelect'].changeDeptByName(this.deptName)
      }, 500)
    },
    async selectDept(arr) {
      //项目部2-组3
      if (arr && arr.length >= 2) {
        this.formData.dept_id = arr[arr.length - 1].dpt_id
        let deptNames = arr.map(_ => {
          return _.name
        })
        this.formData.dept_name = deptNames.join('/')
        // 部门成员加载
        if (arr[arr.length - 2]) {
          let dept_m = arr[arr.length - 2]
          this.dept_manage = await this.getGroupMembers({ dpt_id: dept_m.dpt_id, name: dept_m.name })
        }
        //组员成员加载 0,1,2
        if (arr[arr.length - 1]) {
          let dept_g = arr[arr.length - 1]
          this.dept_group = await this.getGroupMembers({ dpt_id: dept_g.dpt_id, name: dept_g.name })
        }
        //默认勾选在线人员
        setTimeout(() => {
          this.defaultSelected()
        }, 100)

      } else {
        this.formData.dept_id = null
        this.formData.dept_name = null
      }
    },
    //获取部门信息及其成员
    async getGroupMembers(dept) {

      let members = []
      let searchCondition = { page_size: 50, dpt_id: dept.dpt_id, current_page: 1 }
      let { list, pages } = await this.$api.getDptUsers(searchCondition)
      members = [...members, ...list]
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getDptUsers(searchCondition)
        members = [...members, ...list]
      }
      return { dpt_id: dept.dpt_id, name: dept.name, members: members }
    },
    handleCheckAll() {
      this.formData.m_leader_ids = []
      this.formData.leader_ids = []
      if (this.checkAll) {
        //默认勾选在职
        this.dept_manage.members.forEach(member => {
          if (member.is_leave !== 'Y') {
            this.formData.m_leader_ids.push(member)
          }
        })
        // this.handleChange()
        this.dept_group.members.forEach(member => {
          if (member.is_leave !== 'Y') {
            this.formData.leader_ids.push(member)
          }
        })
      }
      this.handleChange()
    },
    defaultSelected() {

      this.formData.m_leader_ids = []
      this.formData.leader_ids = []
      //默认勾选在职
      this.dept_manage.members.forEach(member => {
        if (member.is_leave !== 'Y') {
          this.formData.m_leader_ids.push(member)
        }
      })
      // this.handleChange()
      this.dept_group.members.forEach(member => {
        if (member.is_leave !== 'Y') {
          this.formData.leader_ids.push(member)
        }
      })
      this.checkAll = true
      //切换
      this.handleChange()
    },
    handleChange() {
      this.selectMembers = [...this.formData.leader_ids, ...this.formData.m_leader_ids]
    },
    onSubmit() {
      // this.close()
      this.loadingCommit = true
      this.selectMembers = [...this.formData.leader_ids, ...this.formData.m_leader_ids]
      if (this.selectMembers.length === 0) {
        this.msgWarn('请先勾选需要初始化绩效的部门成员！！！')
        return false
      }
      this.$confirm(`确认初始化勾选的【${this.selectMembers.length}】个部门成员[${this.searchCondition.year}年第${this.searchCondition.quarter}季度]绩效数据`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.selectMembers.forEach((member) => {
          this.handleAdd(member)
        })
      })
    },
    async handleAdd(member) {
      //添加季度信息
      let labelInfo = this.labelMap.find(value => value.position.trim() === member.position.trim())
      let position_label = labelInfo ? labelInfo.position_label : 3
      let postData = {
        name: member.name,
        userid: member.userid,
        position_label: position_label,
        position: member.position,
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter
      }

      // Object.assign(member, {
      //   year: this.searchCondition.year,
      //   quarter: this.searchCondition.quarter
      // })
      this.loadingCommit = true
      let id = await this.$api.saveEcpMerchantQuarter(postData)
      if (id) {
        this.initMembers.push(member)
      }

      setTimeout(() => {
        if (this.initMembers.length === this.selectMembers.length)
          this.loadingCommit = false
      }, 500)
    }


  },
  mounted() {
    this.onOpen()
  }
}
</script>

<style scoped>
.member-box {
  border: #0C0C0C 1px dashed;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
}
</style>
