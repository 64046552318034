<template>
  <div>
    <!--   商品部组绩效目标： 筛选条件-->
    <el-row>
      <el-col :span='24'>
        <!--        筛选条件-->
        <ConditionView @searchEvent='searchEvent' style='display: inline-block' />
        <!--        操作项-->
        <el-form inline style='display: inline-block' size='mini'>
          <el-form-item>
            <el-button type='primary' @click='getList' icon='el-icon-search' v-if='userPermissions.indexOf("ecp_merchant_list")>-1'>查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='handleAdd' icon='el-icon-plus' v-if='userPermissions.indexOf("ecp_merchant_create")>-1'>添加</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type='success' @click='handleEdit' :disabled='single' icon='el-icon-edit' v-if='userPermissions.indexOf("ecp_merchant_create")>-1'>修改</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type='warning' @click='handleInit' icon='el-icon-refresh' v-if='userPermissions.indexOf("ecp_merchant_create")>-1'>初始化</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type='danger' :disabled='multiple' @click='handleDelete' icon='el-icon-delete' v-if='userPermissions.indexOf("ecp_merchant_delete")>-1'>删除</el-button>
          </el-form-item>
<!--          <el-form-item>-->
<!--            <el-button type='primary' icon='el-icon-view'>历史绩效</el-button>-->
<!--          </el-form-item>-->
        </el-form>
      </el-col>
    </el-row>

    <!--    绩效数据-->
    <el-row>
      <el-col :span='24'>
        <el-table :data='quarterDataList' :row-class-name='tableRowClassName' @row-dblclick='handleEdit'
                  @selection-change='handleSelectionChange' :default-sort="{prop: 'position_label', order: 'ascending'}"
                  border>
          <el-table-column type='selection' width='55' align='center' />
          <el-table-column label='序号' type='index' width='80' align='center'></el-table-column>
          <el-table-column label='姓名' prop='name' align='center' sortable></el-table-column>
          <el-table-column label='职位' prop='position_label' align='center' width='140' sortable>
            <template slot-scope='{row}'>
              <span :title='`${row.position_label}`'>{{ row.position }}</span>
              <span style='font-size: 0.9em;font-weight: lighter'>({{ positionLabelAlias[row.position_label] }})</span>
            </template>
          </el-table-column>
          <!--          上季度GMV-->
          <el-table-column label='上季度' align='center'>
            <el-table-column
              v-for='(month) in lastQuarterlyMonths' :key='`last_month_${month}`'
              :prop='`real_gmv_${month}`'
              :label='`${month}月份营收`'
              min-width='100' header-align='center' align='right'>
            </el-table-column>
            <!--          上季度GMV均值-->
            <el-table-column prop='last_gmv_agv' label='上季平均' align='right' header-align='center' width='120' sortable>
              <template slot-scope='{row,column}'>
                <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
              </template>
            </el-table-column>
          </el-table-column>


          <!--          本季度GMV-->
          <el-table-column v-for='(month,index) in quarterlyMonths' :key='index' :label='`${month}月`'
                           align='center'>
            <el-table-column
              :prop='`quotient_${month}`'
              label='浮动系数'
              min-width='100' header-align='center' align='right'>
              <template slot-scope='{row,column}'>
                <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :prop='`guard_gmv_${month}`'
              :label='`${month}月目标`'
              min-width='100' header-align='center' align='right'>
              <template slot-scope='{row,column}'>
                <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :prop='`real_gmv_${month}`'
              :label='`${month}月实际`'
              min-width='100' header-align='center' align='right'>
              <template slot-scope='{row,column}'>
                <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :prop='`bonus_${month}`'
              :label='`${month}月奖金`'
              min-width='100' header-align='center' align='right'>
              <template slot-scope='{row,column}'>
                <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

<!--    <div>-->
<!--          <pre>-->
<!--            计算公式说明： Achievement of investment goals Merchants goals-->
<!--              1.上季平均=（10月份营收+11月份营收+12月份营收）/ 3-->
<!--              2.浮动系数=1月目标/上季平均-->
<!--              3.1月目标=项目组1月保底总和-->
<!--              4.奖金计算公式，岗位不同，计算公式不同-->
<!--                  4.1招商专员：（1月实际-1月目标）* 3% *65%-->
<!--                  4.2招商负责人：招商专员奖金总额 * 25% * 团队完成系数-->
<!--                        *团队完成系数定义：-->
<!--                        ①招商部门营收目标完成率大于等于80%，系数为1；-->
<!--                        ②招商部门营收目标完成率大于等于50%且小于80%，以实际完成系数为准；-->
<!--                        ③若招商部门营收目标完成率小于50%，则系数为0-->
<!--                  4.3支撑岗位：-->
<!--                        ①奖金额=招商个人奖金总额*10%-->
<!--                        ②个人具体比例由招商负责人按月度个人绩效确定。-->
<!--                        ③支撑岗位包括助理、类目运维。-->
<!--                        招商助理及类目运维每月单人奖金最高封顶5000元；招商专员每月奖金最高封顶10000元。-->
<!--          </pre>-->
<!--    </div>-->

    <!--    添加成员-->
    <CreateEditMerchant :visible.sync='dialogVisible' :id='currentId' :search-condition='searchCondition'
                        @close='getList' />
    <!--    初始化-招商部成员-->
    <MerchantMemberInit ref='refMerchantMemberInit' :visible.sync='dialogVisibleInit'
                    v-if="dialogVisibleInit"    :search-condition='searchCondition'
                        @close='getList' />

  </div>
</template>

<script>
import ConditionView from '@/pages/commerce/performance/ConditionView'
import CreateEditMerchant from '@/pages/commerce/performance/CreateEditMerchant'
import MerchantMemberInit from '@/pages/commerce/performance/MerchantMemberInit'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductDeptView',
  components: { MerchantMemberInit, CreateEditMerchant, ConditionView },
  data() {
    return {
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      dataList: [],
      searchCondition: {
        year: null,
        quarter: null
      },
      dialogVisible: false,
      currentId: null,
      dialogVisibleInit: false,//招商部-参与绩效成员初始化
      positionLabelAlias: {
        1: '总监',
        2: '专员',
        3: '助理'
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    //上季度月份
    lastQuarterlyMonths() {
      let lastMonths = []
      if (this.searchCondition.year && this.searchCondition.quarter) {
        // 计算上季度月份数据
        let lastQuarter = this.searchCondition.quarter - 1
        if (lastQuarter === 0) {
          lastQuarter = 4
        }
        lastMonths = [lastQuarter * 3 - 2, lastQuarter * 3 - 1, lastQuarter * 3]
      }

      return lastMonths
    },
    // 本季度月份
    quarterlyMonths() {
      let months = []
      if (this.searchCondition.quarter > 0)
        months = [this.searchCondition.quarter * 3 - 2, this.searchCondition.quarter * 3 - 1, this.searchCondition.quarter * 3]

      return months
    },
    quarterDataList() {
      // let data = [...this.dataList]
      let data = []
      this.dataList.forEach(row => {
        //上季度数据映射
        // let lastQuarterlGmv = 0
        this.lastQuarterlyMonths.forEach(lastMonth => {
          // console.log(lastMonth)
          // console.log(`第${lastMonth % 3}个月`)
          if (lastMonth % 3 === 1) {
            row[`real_gmv_${lastMonth}`] = row.last_gmv_first
          } else if (lastMonth % 3 === 2) {
            row[`real_gmv_${lastMonth}`] = row.last_gmv_second
          } else if (lastMonth % 3 === 0) {
            row[`real_gmv_${lastMonth}`] = row.last_gmv_third
          }
          // lastQuarterlGmv += row[`real_gmv_${lastMonth}`] * 1
        })
        // 上季度GMV均值
        row['last_gmv_agv'] = (row.last_gmv_first / 1 + row.last_gmv_second / 1 + row.last_gmv_third / 1) / 3
        // 本季度月份数据
        let items = row.items || []
        items.forEach(_ => {
          // console.log(_)
          // row[`net_profit_${_.month}`] = _.net_profit
          row[`quotient_${_.month}`] = _.quotient
          row[`guard_gmv_${_.month}`] = _.guard_gmv
          row[`real_gmv_${_.month}`] = _.real_gmv
          row[`bonus_${_.month}`] = _.bonus
        })

        data.push(row)
      })

      return data
    }
  },
  methods: {
    changeYear() {

    },
    changeQuarter() {
      //计算季度对应的月份
    },
    handleAdd() {
      this.currentId = null
      this.dialogVisible = true
    },
    // eslint-disable-next-line no-unused-vars
    handleEdit(row, column, event) {
      // console.log(row)
      this.currentId = row.id
      // this.$notify({ message: `ID:${this.currentId}` })
      this.dialogVisible = true
    },
    async getList() {

      this.dataList = []
      let searchCondition = Object.assign(this.searchCondition, { page_size: 100, current_page: 1 })
      let { list, pages } = await this.$api.getEcpMerchantQuarterList(searchCondition)
      this.dataList = [...list]
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getEcpMerchantQuarterList(searchCondition)
        this.dataList = [...this.dataList, ...list]
      }

    },
    searchEvent(val) {
      this.searchCondition = val
      this.getList()
      // console.log(this.searchCondition)
    },
    handleInit() {
      this.dialogVisibleInit = true
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    handleDelete() {
      const ids = this.ids
      this.$confirm('是否确认删除 勾选的"' + ids.length + '"条数据?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let del_count = await this.$api.deleteEcpMerchantQuarter(ids)
        if (del_count) {
          this.msgSuccess(`已删除[${del_count}]条数据`)
          await this.getList()
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      if (row.is_group) {
        return 'group-row'
      } else if (row.is_dept) {
        return 'dept-row'
      }
      return 'pointer'
    }
  },
  created() {
    this.getList()
  }
}
</script>

<style scoped>

</style>

<style>


</style>
