<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' width='70%' fullscreen :title='dialogTitle'
               :append-to-body='true'>
      <el-form ref='elForm' :model='formData' label-width='100px'>
        <el-row :gutter='10'>
          <el-col :span='8'>
            <div class='card'>
              <div class='card-title'>基本信息<span v-if='formData.id'>(ID:{{ formData.id }})</span></div>
              <div class='card-box'>
                <el-form :inline='false' label-position='right'>
                  <el-form-item label='姓名：'>
                    <span>{{ formData.name || `- 请选择公司『${defaultDeptName}』员工 -` }}</span> &nbsp;&nbsp;
                    <el-button type='text' icon='el-icon-edit' @click='showMemberSelection'></el-button>
                    <!--                    <el-input v-model='formData.name' placeholder='请选择公司员工'></el-input>-->
                  </el-form-item>
                  <el-form-item label='职位：'>
                    <!--                    <el-input v-model='formData.position' placeholder='请选择员工职位'></el-input>-->
                    <span>{{ formData.position || '- 请选择员工职位 -' }}</span>
                  </el-form-item>
                  <el-form-item label='岗位（奖金计算）：'>
                    <el-radio-group v-model='formData.position_label'>
                      <el-radio-button :label='1'>总监</el-radio-button>
                      <el-radio-button :label='2'>专员</el-radio-button>
                      <el-radio-button :label='3'>助理</el-radio-button>
                    </el-radio-group>
                    <i class='el-icon-info' style='margin-left: 10px' :title='`奖金计算公式，岗位不同，计算公式不同，请选择岗位奖金计算方式`'></i>
                    <!--                    <span>{{ formData.position_label || '- 请选择绩效计算类型（总监、专员、助理） -' }}</span>-->
                  </el-form-item>
                  <el-form-item>
                    <el-button type='primary' @click='handleAdd' :loading='loadingCommit' :disabled='loadingCommit'>保存
                    </el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-col>
          <el-col :span='16'>
            <div class='card'>
              <div class='card-title'>上季度数据</div>
              <div class='card-box'>
                <el-form :inline='false' label-position='right'>
                  <div style='display: flex;flex-direction: row'>
                    <div style='width: 50%'>
                      <el-form-item :label='`${lastQuarterlyMonths[0]}月营收：`'>
                        <el-input v-model='formData.last_gmv_first' style='width: 225px' clearable
                                  placeholder='保留2位小数位' oninput="value=value.replace(/[^\d^\.^\-]/g,'')"></el-input>
                      </el-form-item>
                      <el-form-item :label='`${lastQuarterlyMonths[1]}月营收：`'>
                        <el-input v-model='formData.last_gmv_second' style='width: 225px' clearable
                                  placeholder='保留2位小数位' oninput="value=value.replace(/[^\d^\.^\-]/g,'')"></el-input>
                      </el-form-item>
                      <el-form-item :label='`${lastQuarterlyMonths[2]}月营收：`'>
                        <el-input v-model='formData.last_gmv_third' style='width: 225px' clearable
                                  placeholder='保留2位小数位' oninput="value=value.replace(/[^\d^\.^\-]/g,'')"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type='primary' @click='handleAdd' :loading='loadingCommit'
                                   :disabled='loadingCommit||!formData.id'>
                          保存
                        </el-button>
                        <el-button type='default' icon='el-icon-refresh' @click='handleAsync' :loading='loadingCommit'
                                   :disabled='loadingCommit' v-if='formData.position_label===1'
                                   :title='`总监：同步项目组的上季度GMV、本季度每月目标`'>
                          同步
                        </el-button>
                      </el-form-item>
                    </div>
                    <div style='width: 50%;'>
                      <el-form-item label='上季平均：'>
                        <!--                        <template slot='label'>-->
                        <!--                          <span >-->
                        <!--                            上季平均：-->
                        <!--                          </span>-->
                        <!--                        </template>-->
                        <i class='el-icon-info'
                           :title='` 上季平均 =（${lastQuarterlyMonths[0]}月份营收+${lastQuarterlyMonths[1]}月份营收+${lastQuarterlyMonths[2]}月份营收）/ 3`' />
                        &nbsp;&nbsp;
                        <span>{{ $utils.numberFormat((100 * (formData.last_gmv_first / 1 + formData.last_gmv_second / 1 + formData.last_gmv_third / 1) / 3) / 100, 2)
                          }}</span>

                      </el-form-item>

                    </div>
                  </div>
                </el-form>
              </div>
            </div>

          </el-col>
        </el-row>

        <!--        月份绩效数据-->
        <el-row :gutter='10'>
          <el-col :span='8' v-for='(month,index) in quarterlyMonths' :key='index'>
            <div class='card'>
              <div class='card-title'>{{ month }}月份数据</div>
              <div class='card-box'>
                <MerchantSingleForm :position-label='formData.position_label' :quarter-data='formData' :month='month'
                                    :data.sync='monthRows[month]' :last-gmv-agv='lastGmVAgv'
                                    v-if='monthRows[month]' />
              </div>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div class='default-tip'>
        <div>
          <h3>计算公式说明：</h3>
        </div>
        <div style='margin-left: 10px'>
          浮动系数 = 本月目标 / 上季平均
        </div>
        <div>
          <h4>奖金计算公式：</h4>
          <ul style='margin-left: 10px'>
            <li>招商专员（专员）：（本月实际-本月目标）* 3% * 65%</li>
            <li>招商负责人（总监）：招商专员奖金总额 / 65% * 25% *
              <el-tooltip placement='top' effect='light'>
                <div slot='content'>*团队完成系数定义：<br />
                  ①招商部门营收目标完成率大于等于80%，系数为1；<br />
                  ②招商部门营收目标完成率大于等于50%且小于80%，以实际完成系数为准；<br />
                  ③若招商部门营收目标完成率小于50%，则系数为0
                </div>
                <el-button type='text'>团队完成系数</el-button>
              </el-tooltip>
            </li>
            <li>支撑岗位(助理)：奖金额 = 招商个人奖金总额 * 10%</li>
          </ul>
        </div>

      </div>
    </el-dialog>

    <!--    选择招商部成员-->
    <SelectDeptMember ref='refSelectDeptMember' :dept-name='defaultDeptName' :visible.sync='dialogVisible'
                      @handleSelect='handleSelectMember' />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MerchantSingleForm from '@/pages/commerce/performance/MerchantSingleForm'
import SelectDeptMember from '@/pages/commerce/components/SelectDeptMember'

export default {
  name: 'CreateEditMerchant',
  components: { SelectDeptMember, MerchantSingleForm },
  props: {
    id: {
      type: [String, Number],
      default() {
        return null
      }
    },
    searchCondition: {
      type: Object,
      default() {
        return {
          year: null, quarter: null
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    dialogTitle() {
      return `${this.id ? '修改（ID：' + this.id + '）' : '添加'} 招商目标(${this.searchCondition.year}年第${this.searchCondition.quarter}季度)`
    },
    // 本季度月份
    quarterlyMonths() {
      let months = []
      if (this.searchCondition.quarter > 0)
        months = [this.searchCondition.quarter * 3 - 2, this.searchCondition.quarter * 3 - 1, this.searchCondition.quarter * 3]

      return months
    },
    //上季度月份
    lastQuarterlyMonths() {
      let lastMonths = []
      if (this.searchCondition.year && this.searchCondition.quarter) {
        // 计算上季度月份数据
        let lastQuarter = this.searchCondition.quarter - 1
        if (lastQuarter === 0) {
          lastQuarter = 4
        }
        lastMonths = [lastQuarter * 3 - 2, lastQuarter * 3 - 1, lastQuarter * 3]
      }

      return lastMonths
    },
    //上季度均值
    lastGmVAgv() {
      return (this.formData.last_gmv_first / 1 + this.formData.last_gmv_second / 1 + this.formData.last_gmv_third / 1) / 3
    }
  },
  data() {
    return {
      dialogVisible: false,
      loadingCommit: false,
      formData: { name: '', userid: '', position_label: 1, position: '' },
      monthRows: {},
      statusOption: [
        { label: '有效', value: 'Y' },
        { label: '无效', value: 'N' }
      ],
      loadingMapper: false,
      kolMapperId: null,
      kolMapperList: [],
      positionLabelAlias: {
        1: '总监',
        2: '专员',
        3: '助理'
      },
      labelMap: [
        { position: '总监', position_label: 1 },
        { position: '招商专员', position_label: 2 },
        { position: '招商助理', position_label: 3 },
        { position: '采购助理', position_label: 3 }
      ],
      defaultDeptName: '招商部',
      //招商专员奖金总和
      totalBonus: 0
    }
  },
  methods: {
    onOpen() {
      this.loadingCommit = false
      this.formData = { name: '', userid: '', position_label: 1, position: '' }
      let monthRows = {}
      this.quarterlyMonths.forEach((month) => {
        monthRows[month] = {
          artist_id: this.formData.artist_id,
          nickname: this.formData.nickname,
          year: this.searchCondition.year,
          quarter: this.searchCondition.quarter,
          month: month,
          quotient: null,
          guard_gmv: null,
          real_gmv: null,
          bonus: null
        }
      })
      this.monthRows = { ...monthRows }
      this.getAInfo()
    },
    onClose() {

    },
    close() {
      this.$emit('update:visible', false)
    },
    // 季度数据
    async getAInfo() {
      if (this.id) {
        let { info } = await this.$api.getEcpMerchantQuarterInfo(this.id)
        this.formData = { ...info }
        // 加载 【月度目标和实际情况(list 大小最大为3)】
        let { list } = await this.$api.getEcpMerchantMonthList({ pid: this.id, page_size: 10, current_page: 1 })
        let monthRows = {}
        this.quarterlyMonths.forEach((month) => {
          // 若已保存过， 则使用上次保存的数据
          let savedData = list.find(value => value.month === month)
          if (savedData) {
            monthRows[month] = { ...savedData }
          } else {
            monthRows[month] = {
              artist_id: this.formData.artist_id,
              nickname: this.formData.nickname,
              year: this.searchCondition.year,
              quarter: this.searchCondition.quarter,
              month: month,
              quotient: null,
              guard_gmv: null,
              real_gmv: null,
              bonus: null
            }
          }

        })
        this.monthRows = { ...monthRows }
      }
    },
    async handleAdd() {
      //添加季度信息
      // this.$api.saveEcpMerchantQuarter(this.formData)
      this.loadingCommit = true
      let id = await this.$api.saveEcpMerchantQuarter(Object.assign(this.formData, {
        year: this.searchCondition.year,
        quarter: this.searchCondition.quarter
      }))
      if (id) {
        this.formData.id = id
      }

      setTimeout(() => {
        this.loadingCommit = false
      }, 500)
    },
    handleSelectMember(member) {
      // console.log(member)
      this.formData.name = member.name
      this.formData.userid = member.userid
      this.formData.position = member.position
      let labelInfo = this.labelMap.find(value => value.position.trim() === member.position.trim())
      this.formData.position_label = labelInfo ? labelInfo.position_label : 3
    },
    showMemberSelection() {
      this.dialogVisible = true
      setTimeout(() => {
        this.$notify.success('默认选择：招商部')
        this.$refs['refSelectDeptMember'].changeDept(this.defaultDeptName)
      }, 500)
    },
    async handleAsync() {
      //同步总监的数据
      let { last_quarter_summary, quarter_summary } = await this.$api.getEcpMerchantQuarterSummary(this.searchCondition)
      this.formData = { ...this.formData, ...last_quarter_summary }
      Object.keys(this.monthRows).forEach((i) => {
        let item = this.monthRows[i]
        let month = item.month
        let summary = quarter_summary.find(value => value.month === month)
        this.monthRows[i].guard_gmv = summary.guard_gmv
      })

    }
  }
}
</script>

<style scoped>
.card {
  line-height: 400px;
  width: 100%;
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 5px;
}

.card-box {
  padding: 10px 20px;
  line-height: 350px;
  height: 350px;
  border: #2c2727 1px dashed;
  margin-bottom: 20px;
}
</style>