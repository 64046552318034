<template>
  <div style='margin: auto' class="ecp-table-child">
    <el-table :span-method="spanMethod" :data='dataList' show-summary :summary-method='getSummaries'
               @row-dblclick='handleEdit' border>
      <el-table-column type='index' label='序号' width="55" align='center'></el-table-column>
      <el-table-column label='操作' width="80" align='center' v-if="setAuthority('ecp_team_auth')">
        <template slot-scope='{row,$index}'>
<!--          <el-button type='text' @click='handleEdit(row)'-->
<!--          ><i class='el-icon-edit-outline'></i>编辑-->
<!--          </el-button>-->
          <el-button  v-if="setAuthority('ecp_team_delete')" type='text' @click='handleDelete(row,$index)'
          ><i class='el-icon-remove'></i>删除
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop='nickname' label='红人' width="100 " align='center'
                       :key="Math.random()" show-overflow-tooltip></el-table-column>

      <!--          上季度GMV-->
      <el-table-column :key="Math.random()" label='上季度总利润' width="120" prop='last_total_profit' align='right'>
        <template slot-scope='{row}'>
          <span>{{ moneyFormat(row.last_total_profit) }}</span>
        </template>
      </el-table-column>
      <!--          上季度GMV均值-->
<!--      <el-table-column  prop='last_gmv_agv' width="120" label='上季平均' header-align='center'-->
<!--                        :key="Math.random()"      align='left'>-->
<!--        <template slot-scope='{row,column}'>-->
<!--          <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column  prop='last_gmv_agv' width="120" label='上季平均' header-align='center'
                        :key="Math.random()"      align='right'>
        <template slot-scope='{row,column}'>
          <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
        </template>
      </el-table-column>
      <!--          本季度GMV-->
      <template v-for='(month) in quarterlyMonths' :label='`${month}月`'
            height="200" align='center'>
        <el-table-column
            :key='`net_profit_${month}`'
            :prop='`net_profit_${month}`'
            :label='`${month}月净利润`'
            width="120" show-overflow-tooltip header-align='center' align='right'>
          <template slot-scope='{row,column}'>
            <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            :key='`quotient_${month}`'
            :prop='`quotient_${month}`'
            width="120" show-overflow-tooltip label='浮动系数'
            header-align='center' align='right'>
          <template slot-scope='{row,column}'>
            <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            :key='`guard_gmv_${month}`'
            :prop='`guard_gmv_${month}`'
            width="120" show-overflow-tooltip :label='`${month}月保底`'
            header-align='center' align='right'>
          <template slot-scope='{row,column}'>
            <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column
            :key='`real_gmv_${month}`'
            width="120" show-overflow-tooltip :prop='`real_gmv_${month}`'
            :label='`${month}月实际`'
            header-align='center' align='right'>
          <template slot-scope='{row,column}'>
            <span>{{ row[column.property] ? $utils.numberFormat(row[column.property], 2) : '' }}</span>
          </template>
        </el-table-column>
      </template>

    </el-table>
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'
import { mapGetters } from 'vuex'

export default {
  name: 'TeamBonusMember',
  components: { EditableCell },
  props: {

    groupData: {
      type: Object,
      default() {
        return {
          dept_name: '项目1组', year: 2023, month: 5, bonus: 12000, status: 1
        }
      }
    },
    memberList: {
      type: Array,
      default() {
        return []
      }
    },
    quarterlyMonths: {
      type: Array,
      default() {
        return []
      }
    },
    lastQuarterlyMonths: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    dataList: {
      get() {
        return this.memberList || []
      },
      set(val) {
        this.$emit('update:memberList', val)
      }
    }
  },
  data() {
    return {
      searchCondition: {},
      total: 0,
      showEditIcon: true,
      mergeFields: ['nickname'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录

      // dataList: [{ dept_name: '项目1组', year: 2023, month: 5, group_bonus: 12000, ratio: 0.2, my_bonus: 1000 }]
    }
  },
  watch: {
    dataList: {
      immediate: true,
      handler(val) {
        if(val?.length!=0){
          this.rowspan()
        }
      }
    }
  },
  methods: {
    setAuthority(val) {
      return this.userPermissions.indexOf(val) > -1
    },
    expandChange(row) {
      this.expandRowKey = row.group_id
    },
    handleDelete(row) {
      this.$confirm('是否确认删除"' + row.nickname + '"的数据?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let del_count = await this.$api.deleteEcpQuarter({ ids: [row.id] })
        if (del_count) {
          this.msgSuccess(`删除成功`)
          // await this.getList()
          this.$emit('getList')
        }
      })
    },
    spanMethod({ rowIndex, column }) {
      const fields = this.mergeFields
      if (column.property && fields.indexOf(column.property) > -1 && this.spanObj[column.property]) {
        const _row = this.spanObj[column.property][rowIndex] || 0
        const _col = _row || 0 > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },

    rowspan() {
      const fields = this.mergeFields //合并的字段
      this.dataList.forEach((item, index) => {
        if (index === 0) {
          this.spanObj[fields] = []
          this.spanObj[fields].push(1)
          this.positionObj[fields] = 0
        } else {
          // 合并数据：若和上一行的数据相同，则该字段合并行数加1
          if (this.dataList[index][fields] === this.dataList[index - 1][fields]) {
            this.spanObj[fields][this.positionObj[fields]] += 1
            this.spanObj[fields].push(0)
          } else {
            this.spanObj[fields].push(1)
            this.positionObj[fields] = index
          }
        }
      })
      // console.log(this.positionObj[fields])
    },
    // eslint-disable-next-line no-unused-vars
    editVal(row, colName) {
      if (colName === 'ratio') {
        row.my_bonus = this.bonus * row.ratio / 100
        //保存 分配的奖金占比和金额
        this.$api.saveEcpBonusMonthMember({ id: row.id, ratio: row.ratio, my_bonus: row.my_bonus })
      }

      // this.$emit('editVal', this.dataList)// 通知父组件 修改后的表格数据
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = ''
          return
        }
        if (['ratio', 'my_bonus'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[index] += ' '
          } else {
            sums[index] = 'N/A'
          }

          switch (column.property) {
            case 'ratio':
              sums[index] = this.$utils.numberFormat(sums[index], 0) + ' %'
              break
              // case 'month_amount':
              //   sums[index] = '¥' + this.moneyFormat(sums[index])
              //   break
            default:
              sums[index] = '¥ ' + this.$utils.numberFormat(sums[index], 2)
              break
          }
        }

      })

      return sums
    },
    moneyFormat(val) {
      return this.$utils.numberFormat(val, 2)
    },
    numFormat(val) {
      return this.$utils.numberFormat(val, 0)
    },
    handleEdit(row) {
      if(this.userPermissions.indexOf('ecp_team_update') !== -1){
        this.$emit('editRow', row.id)
      }

    }
  }
}
</script>

<style scoped>

</style>
