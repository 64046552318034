import { render, staticRenderFns } from "./TeamSingleForm.vue?vue&type=template&id=6bda78ed&scoped=true&"
import script from "./TeamSingleForm.vue?vue&type=script&lang=js&"
export * from "./TeamSingleForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bda78ed",
  null
  
)

export default component.exports