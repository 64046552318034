<template>
  <div>
    <el-form inline :model='searchCondition' size='mini'>
      <el-form-item>
        <EcpDeptSelect class='select-custom' ref='ecp_dept'
                       @handleSelect='selectDept'/>
        <!--        <DeptCascaderScope :type="{type:'ecp_team_data'}" ref="refDeptCascaderScope" :show-first-group="true"-->
        <!--                      :showFirstGroup="false"     :selectUrl="'optionEcpBonusMember'"  style="display: inline-block" @selectedDept="selectDept" @firstGroup="currentGroup"/>-->
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model='searchCondition.year'
          type='year'
          format='yyyy年'
          value-format='yyyy'
          placeholder='选择年度' style='width: 140px' @change='searchEvent'>
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-popover
          placement='right'
          title=''
          v-model="popShow"
          width='500'
          trigger='click'
        >
          <el-button type='default' slot='reference'>
            选择季度<span v-if='searchCondition.quarter'>: 第 {{ searchCondition.quarter }} 季度</span>
          </el-button>
          <el-row>
            <el-col :span='24'>
              <el-radio-group v-model='searchCondition.quarter' @change='searchEvent'>
                <el-radio-button v-for='(quarter) in quarters' :label='quarter.value' :key='quarter.label'>
                  {{ quarter.label }}
                </el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
        </el-popover>
      </el-form-item>

    </el-form>
  </div>
</template>

<script>
import EcpDeptSelect from '@/pages/commerce/kol_mapper/EcpDeptSelect'


export default {
  name: 'ConditionView',
  components: { EcpDeptSelect },

  data() {
    return {
      searchCondition: {
        year: null,
        quarter: null
      },
      quarters: [
        { label: '未选季度', value: 0 },
        { label: '第1季度', value: 1 },
        { label: '第2季度', value: 2 },
        { label: '第3季度', value: 3 },
        { label: '第4季度', value: 4 }
      ],
      popShow:false
    }
  },
  methods: {
    async setGroupInfo(group_id, group_name) {
      // this.$refs['refDeptCascaderScope'].initVal(group_id)
      this.searchCondition.group_id = group_id
      this.searchCondition.group_name = group_name
      this.searchEvent()
    },
    async currentGroup(group) {
      if (group && group.dpt_id) {
        await this.setGroupInfo(group.dpt_id, group.name)
      }
    },
    async selectDept(arr) {
      if(arr&&arr.length>0){
        let group_id = null
        let group_name = null
        group_id = arr[arr.length - 1].dpt_id
        group_name = arr[arr.length - 1].name
        await this.setGroupInfo(group_id, group_name)
      }else {
        await this.setGroupInfo()
      }
    },
    searchEvent() {
      //触发查询
      if (!this.searchCondition.group_id) {
        delete this.searchCondition.group_id
        delete this.searchCondition.group_name
      }
      this.$emit('searchEvent', this.searchCondition)
      this.popShow = false
    }
  },
  created() {
    //这里要修改
    this.searchCondition.year = new Date().getFullYear() + ''
    const currentMonth = 1 + new Date().getMonth()

    //计算当前季度
    this.searchCondition.quarter = Math.ceil(currentMonth / 3)
    this.searchEvent()
  }
}
</script>

<style scoped>

</style>
