<template>
  <div>
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' width='1200px' modal-append-to-body
               :title='dialogTitle'>
      <el-form>
        <el-form-item label='选择部门：'>
          <EcpDeptSelect ref='ecp_dingTalk_dept' @handleSelect='selectDept' />
        </el-form-item>
        <el-form-item label='部门成员：'>
          <div style='display: block;'>
            <span v-if='dept_group.name'> 『{{ dept_group.name || '' }}』</span>
            <br />
            <div class='member-box'>
              <el-radio-group v-model='formData.leader_id' @change='handleLeaderSelect'>
                <el-radio v-for='(member,index) in dept_group.members' :key='index' :value='member.userid'
                          :label='member.userid'>
                  <span :title='`钉钉用户ID：${member.userid}`'>  {{ member.name }}</span>
                  <span v-if='member.position'> 『{{ member.position }}』</span>
                  <span v-if="member.is_leave==='Y'" style='color: #F56C6C'>({{ member.is_leave_alias }})</span>
                  <span v-else>({{ member.is_leave_alias }})</span>
                </el-radio>
              </el-radio-group>
            </div>

          </div>
        </el-form-item>
        <el-form-item label='上级部门：'>
          <div style='display: block;'>
            <span v-if='dept_manage.name'> 『{{ dept_manage.name || '' }}』</span>
            <br />
            <div class='member-box'>
              <el-radio-group v-model='formData.leader_id' @change='handleMLeaderSelect'>
                <el-radio v-for='(member,index) in dept_manage.members' :key='index' :value='member.userid'
                          :label='member.userid'>
                  <span :title='`钉钉用户ID：${member.userid}`'>  {{ member.name }}</span>
                  <span v-if='member.position'> 『{{ member.position }}』</span>
                  <span v-if="member.is_leave==='Y'" style='color: #F56C6C'>({{ member.is_leave_alias }})</span>
                  <span v-else>({{ member.is_leave_alias }})</span>
                </el-radio>
              </el-radio-group>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div solt='footer' style='text-align: center'>
        <el-button type='primary' size='medium' @click='onSubmit'>确认</el-button>
        <el-button @click='close' size='medium' >取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>


import EcpDeptSelect from '@/pages/commerce/kol_mapper/EcpDeptSelect'

export default {
  name: 'SelectDeptMember',
  components: { EcpDeptSelect },
  computed: {
    dialogTitle() {
      return `选择部门-成员：数据来自钉钉`
    }
  },
  props: {
    deptName: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      loadingCommit: false,
      formData: {
        dept_id: null,
        dept_name: null,
        leader_id: null,
        leader_name: null,
        m_leader_id: null,
        m_leader: null
      },
      dept_group: {
        dpt_id: '',
        name: '',
        members: []
      },
      dept_manage: {
        dpt_id: '',
        name: '',
        members: []
      },
      selectMember: {}
    }
  },
  methods: {
    onOpen() {
      this.loadingCommit = false
      this.dept_group = {
        dpt_id: '',
        name: '',
        members: []
      }
      this.dept_manage = {
        dpt_id: '',
        name: '',
        members: []
      }
      if (this.$refs['ecp_dingTalk_dept'])
        this.$refs['ecp_dingTalk_dept'].init()
    },
    onClose() {

    },
    close() {
      this.$emit('update:visible', false)
    },
    changeDept() {
      if (this.$refs['ecp_dingTalk_dept'])
        this.$refs['ecp_dingTalk_dept'].changeDeptByName(this.deptName)
    },
    async selectDept(arr) {
      //项目部2-组3
      if (arr && arr.length >= 2) {
        this.formData.dept_id = arr[arr.length - 1].dpt_id
        let deptNames = arr.map(_ => {
          return _.name
        })
        this.formData.dept_name = deptNames.join('/')
        // 部门成员加载
        if (arr[arr.length - 2]) {
          let dept_m = arr[arr.length - 2]
          this.dept_manage = await this.getGroupMembers({ dpt_id: dept_m.dpt_id, name: dept_m.name })
        }
        //组员成员加载 0,1,2
        if (arr[arr.length - 1]) {
          let dept_g = arr[arr.length - 1]
          this.dept_group = await this.getGroupMembers({ dpt_id: dept_g.dpt_id, name: dept_g.name })
        }
      } else {
        this.formData.dept_id = null
        this.formData.dept_name = null
      }

    },
    // 处理搜索结果的选中
    handleMLeaderSelect(userid) {
      let member = this.dept_manage.members.find(value => value.userid == userid)
      this.formData.m_leader = member.name
      this.selectMember = member
      // this.$emit('handleSelect', member)
    },
    // 处理搜索结果的选中
    handleLeaderSelect(userid) {
      // this.formData.leader_id = item.value
      let member = this.dept_group.members.find(value => value.userid == userid)
      this.formData.leader_name = member.name
      this.selectMember = member
      // this.$emit('handleSelect', member)
    },
    //获取部门信息及其成员
    async getGroupMembers(dept) {

      let members = []
      let searchCondition = { page_size: 50, dpt_id: dept.dpt_id, current_page: 1 }
      let { list, pages } = await this.$api.getDptUsers(searchCondition)
      members = [...members, ...list]
      searchCondition.page_size = pages.page_size
      searchCondition.current_page = pages.current_page
      for (let i = pages.offset + list.length; i < pages.total; i += pages.page_size) {
        searchCondition.current_page += 1
        let { list } = await this.$api.getDptUsers(searchCondition)
        members = [...members, ...list]
      }
      return { dpt_id: dept.dpt_id, name: dept.name, members: members }
    },
    onSubmit() {
      this.$emit('handleSelect', this.selectMember)
      this.close()
    }

  }
}
</script>

<style scoped>
.member-box {
  border: #0C0C0C 1px dashed;
  padding: 10px;
  border-radius: 5px
}
</style>