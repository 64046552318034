<template>
  <div>
    <el-form :model='formData'>
      <el-form-item>
        浮动系数：
        <span style="margin-right: 10px">
          {{ $utils.numberFormat(formData.guard_gmv / Number(this.average), 2) }}
        </span>
        <!--        <el-input :placeholder='`${currentMonth}月保底 / 上月平均(${average})`' v-model='formData.quotient'-->
        <!--                  style='width: 220px'-->
        <!--                  disabled clearable/>-->
      </el-form-item>
      <el-form-item :label='`${currentMonth}月保底：`' prop='guard_gmv'>
        <el-input :placeholder='`${currentMonth}月保底（保留2位小数位）`' v-model='formData.guard_gmv' style='width: 220px'
          @change="countAverage(formData.guard_gmv)" clearable oninput="value=value.replace(/[^\d^\.^\-]/g,'')" />
      </el-form-item>
      <el-form-item :label='`${currentMonth}月实际：`'>
        <el-input :placeholder='`${currentMonth}月实际（保留2位小数位）`' v-model='formData.real_gmv' style='width: 220px' clearable
          oninput="value=value.replace(/[^\d^\.^\-]/g,'')" />
      </el-form-item>
      <el-form-item :label='`${currentMonth}月净利润：`'>
        <el-input :placeholder='`${currentMonth}月净利润（保留2位小数位）`' v-model='formData.net_profit' style='width: 220px'
          clearable oninput="value=value.replace(/[^\d^\.^\-]/g,'')" />
      </el-form-item>
      <!--      <el-form-item :label='`${currentMonth}月奖金：`'>-->
      <!--        <el-input :placeholder='`${currentMonth}月奖金（保留2位小数位）`' v-model='formData.bonus' style='width: 220px' clearable-->
      <!--                  oninput="value=value.replace(/[^\d^\.^\-]/g,'')" />-->
      <!--      </el-form-item>-->
      <el-form-item style='text-align: center'>
        <!--        <el-button type='warning' @click='publishBonus' :disabled='loadingCommit||!formData.id' :loading='loadingCommit'>-->
        <!--          <i :class='[formData.bonus_checked === 1?"el-icon-circle-check":""]'></i>&nbsp;&nbsp;发布 {{ currentMonth }} 月奖金-->
        <!--        </el-button>-->
        <el-button type='primary' @click='onsubmit' :disabled='loadingCommit || !quarterData.id' :loading='loadingCommit'>
          保存
        </el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script>

export default {
  name: 'TeamSingleForm',
  props: {
    month: {
      type: [Number, String]
    },
    average: {
      type: [Number, String]
    },
    rowIndex: {
      type: Number
    },
    data: {
      type: Object,
      default() {
        return {
          artist_id: null,
          nickname: '',
          year: null,
          month: null,
          quotient: null,
          net_profit: null,
          guard_gmv: null,
          real_gmv: null,
          bonus: null
        }
      }
    },
    quarterData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  inject: {
    getList: { value: 'getList', default: null }
  },
  computed: {
    formData: {
      get() {
        return this.data
      },
      set(val) {
        this.$emit('update:data', val)
      }
    },
    currentMonth: {
      get() {
        return this.month
      },
      set(val) {
        this.$emit('update:month', val)
      }
    }
  },
  data() {
    return {
      loadingCommit: false
    }
  },
  methods: {
    countAverage(value) {
      this.formData.quotient = Number(value) / Number(this.average)
    },
    async onsubmit() {
      this.loadingCommit = true
      let postData = Object.assign(this.formData, {
        year: this.quarterData.year,
        pid: this.quarterData.id,
        artist_id: this.quarterData.artist_id,
        nickname: this.quarterData.nickname,
        quarter: this.quarterData.quarter || this.formData.quarter
      })

      let id = await this.$api.saveEcpMonth(postData)
      setTimeout(() => {
        this.loadingCommit = false
      }, 500)
      if (id) {
        this.formData.id = id//保存计算后的奖金
        // await this.getList()
        // await this.saveBonus()
      }
    },
    // async saveBonus() {
    //   let rowData = this.$attrs.quarterRow[this.rowIndex]
    //   let getBonus = this.$attrs.quarterBonusList
    //   let bonus = 0
    //   let real_gmv = 0
    //   let guard_gmv = 0
    //   let boundList = getBonus.find(value => (value.group_id === rowData.group_id && value.quarter === rowData.quarter))
    //   real_gmv = (rowData[`real_gmv_${this.month}`] || 0)
    //   guard_gmv = (rowData[`guard_gmv_${this.month}`] || 0)
    //   //这是奖金限额
    //   let getMonthBonus = Number(boundList?.bonus)
    //   console.log('奖金:', bonus, '上季总利润:', rowData.last_total_profit, '奖金限额', getMonthBonus)
    //   //
    //   // bonus = bonus <= 0 ? 0 : bonus >= this.$utils.calcBonusQuarter(rowData.last_total_profit) ? this.$utils.calcBonusQuarter(rowData.last_total_profit) : bonus
    //   //按照上季度净利润总额计算出奖金限额
    //   this.$utils.calcBonusQuarter(rowData.last_total_profit)
    //
    //   //奖金计算说明：0.bonus!=0。1.如果设置了当月奖金，则无论如何都按照设置的奖金来。2.如果没有设置当月奖金，则奖金计算值对比上季度净利润总额计算出奖金限额。3.如果没有上季度净利润总额，则等于计算奖金
    //   //当月实际-当月保底 :计算的奖金值
    //   bonus = (Number(real_gmv) - Number(guard_gmv)) * 0.1
    //   //不要小于0
    //   bonus = bonus <= 0 ? 0 : bonus
    //   if (getMonthBonus && getMonthBonus != 0) {
    //     bonus = getMonthBonus
    //   } else if (this.$utils.calcBonusQuarter(rowData.last_total_profit)) {
    //     bonus >= this.$utils.calcBonusQuarter(rowData.last_total_profit) ? this.$utils.calcBonusQuarter(rowData.last_total_profit) : bonus
    //   }
    //
    //   console.log('实际奖金：', bonus)
    //   let postData = {
    //     'year': rowData.year,
    //     'month': this.formData.month,
    //     'quarter': this.formData.quarter,
    //     'group_id': rowData.group_id,
    //     'group_name': rowData.group_name,
    //     'dept_id': rowData.dept_id,
    //     'dept_name': rowData.dept_name,
    //     'bonus': bonus
    //   }
    //   await this.$api.saveEcpMonthBonus(postData)
    // },
    async publishBonus() {
      this.loadingCommit = true
      if (this.formData.id) {
        let info = await this.$api.publishBonusEcpMonth(this.formData.id)
        if (info) {
          this.msgSuccess('已发布奖金')
          this.formData.bonus_checked = 1
        }
        setTimeout(() => {
          this.loadingCommit = false
        }, 500)

      } else
        this.$notify.warning('请先保存，再发版奖金')
    }
  }
}
</script>

<style scoped></style>
